import { Member } from 'src/templates/members/columns'
import { useUpdateMemberRoleMutation } from 'src/enviroment/api/services/membersApi'
import RoleSelect from 'src/components/organisms/RoleSelect'
import { IMember, UserRole } from 'src/types/user'
import useAuth from 'src/enviroment/hooks/useAuth'
import Typography from 'src/components/atoms/Typography'
import { useCallback } from 'react'
import { roles } from 'src/enviroment/constants/constants'

// Separate Role Cell component
const RoleCell = ({ member }: { member: IMember }) => {
  const updateRoleMutation = useUpdateMemberRoleMutation()

  const { user } = useAuth()
  const isCurrentUser = user?.id === member.id

  const getCurrentRoleLabel = useCallback(() => {
    return roles.find((role) => role.value === member.tenantMemberAccess.role)?.label || 'Select Role'
  }, [member.tenantMemberAccess.role])

  if (isCurrentUser) {
    return (
      <Typography variant="base" className=" ml-1 text-light-green-50">
        {getCurrentRoleLabel()}
      </Typography>
    )
  }

  const handleRoleChange = async (newRole: UserRole) => {
    try {
      await updateRoleMutation.mutateAsync({
        userId: member.id,
        role: newRole,
      })
    } catch (error) {
      console.error('Failed to update role:', error instanceof Error ? error.message : 'Unknown error')
    }
  }

  return (
    <RoleSelect
      value={member.tenantMemberAccess.role}
      onRoleChange={handleRoleChange}
      className="border-gray-700 bg-transparent text-gray-100"
    />
  )
}

export default RoleCell
