import { SUBSCRIPTION_PLAN, UserRole } from 'src/types/user'

export const CONSTANT_A = 'CONSTANT_A'

export const roles = [
  {
    label: 'Admin',
    value: UserRole.ADMIN,
  },
  {
    label: 'Contributor',
    value: UserRole.CONTRIBUTOR,
  },
  {
    label: 'Viewer',
    value: UserRole.VIEWER,
  },
]

export const plans = [
  {
    label: 'Free',
    value: SUBSCRIPTION_PLAN.FREE,
  },
  {
    label: 'Premium',
    value: SUBSCRIPTION_PLAN.PREMIUM,
  },
  {
    label: 'Enterprise',
    value: SUBSCRIPTION_PLAN.ENTERPRISE,
  },
]
export const userTypes = [
  {
    id: 'CarbonDeveloper',
    label: 'Carbon developer',
    value: 'CarbonDeveloper',
  },
  {
    id: 'LandOwner',
    label: 'Land owner or steward',
    value: 'LandOwner',
  },
  {
    id: 'Investor',
    label: 'Investor',
    value: 'Investor',
  },
  {
    id: 'Government',
    label: 'Government Representative',
    value: 'Government',
  },
  {
    id: 'Curious',
    label: 'Curious about carbon',
    value: 'Curious',
  },
]

export const userPurposes = [
  {
    id: 'DevelopProject',
    label: 'Looking to develop a carbon project',
    value: 'DevelopProject',
  },
  {
    id: 'FindSolutions',
    label: 'Searching for carbon solutions to my land',
    value: 'FindSolutions',
  },
  {
    id: 'UnderstandMarket',
    label: 'Understanding the market & landscape',
    value: 'UnderstandMarket',
  },
  {
    id: 'Invest',
    label: 'Want to invest in carbon credits',
    value: 'Invest',
  },
]
