import React from 'react'
import NavigationGroup from '../molecules/NavigationGroup'
import Typography from '../atoms/Typography'

interface SidebarSectionProps {
  title: string
  isCollapsed: boolean
  items: Array<{
    to: string
    icon: React.ReactNode
    label: string
    isActive?: boolean
  }>
}

const SidebarSection: React.FC<SidebarSectionProps> = ({ items, isCollapsed, title }) => {
  return (
    <div className="mb-8 space-y-4">
      {!isCollapsed && (
        <Typography
          className={`transition-all duration-300 ${
            !isCollapsed ? 'translate-y-0 opacity-100' : '-translate-y-2 opacity-0'
          }`}
          variant="sm"
        >
          {title}
        </Typography>
      )}
      <NavigationGroup items={items} isCollapsed={isCollapsed} />
    </div>
  )
}

export default SidebarSection
