import { Helmet } from 'react-helmet-async'
import Typography from 'src/components/atoms/Typography'
import mapboxgl, { MapMouseEvent } from 'mapbox-gl'
import MapboxMap from 'src/components/organisms/MapBox'
import { useGetAtlasProjects } from 'src/enviroment/api/services/projectsApi'

const AtlasPage: React.FC = () => {
  const { data: projects } = useGetAtlasProjects()
  const handleMapLoad = (map: mapboxgl.Map) => {
    const list = projects
    if (list?.length) {
      const bounds = new mapboxgl.LngLatBounds()
      list.forEach((project) => {
        bounds.extend([project.longitude, project.latitude])
      })

      map.fitBounds(bounds, {
        padding: 50,
        maxZoom: 10,
      })
    }
  }

  const handleMapClick = (e: MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] }) => {
    console.log('Clicked at:', e.lngLat)
  }

  return (
    <>
      <Helmet>
        <title>RAMO - Atlas</title>
      </Helmet>

      <div className="flex h-full flex-col">
        <div className="flex flex-row items-center justify-between px-5 py-3 text-gray-50">
          <Typography variant="xl">Atlas</Typography>
        </div>

        <div className="relative flex-1">
          <MapboxMap
            initialOptions={{
              style: 'mapbox://styles/mapbox/dark-v11',
              projection: 'globe',
            }}
            projects={projects}
            onMapLoad={handleMapLoad}
            onMapClick={handleMapClick}
          />
        </div>
      </div>
    </>
  )
}

export default AtlasPage
