import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { useProjectsQuery } from 'src/enviroment/api/services/projectsApi'
import ProjectsTemplate from 'src/templates/dashboard/ProjectsTemplate'
import { Project } from 'src/types/projects'

const ProjectsPage: FC = () => {
  const { data, isLoading, error, isFetching } = useProjectsQuery()
  const navigate = useNavigate()

  const handleOpenProject = (project: Project): void => {
    navigate(`/dashboard/project/${project.id}`)
  }

  // Safely handle the data flattening with type checking
  const flattenedProjects: Project[] =
    data?.pages?.reduce<Project[]>((acc, page) => {
      if (Array.isArray(page)) {
        return [...acc, ...page]
      }
      return acc
    }, []) || []

  return (
    <>
      <Helmet>
        <title>RAMO - My Projects</title>
      </Helmet>

      <ProjectsTemplate
        projects={flattenedProjects}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        onOpenProject={handleOpenProject}
      />
    </>
  )
}

export default ProjectsPage
