import ErrorPage from 'src/pages/error-page'
import { RouteObject, Navigate, createBrowserRouter } from 'react-router-dom'
import SignInPage from 'src/pages/auth/sign-in'
import SignUpPage from 'src/pages/auth/sign-up'
import PersonalInfoPage from 'src/pages/auth/tell-us-about-youself'
import Projects from 'src/pages/index'
import Atlas from 'src/pages/dashboard/atlas'
import Team from 'src/pages/dashboard/team'
import DashboardLayout from 'src/enviroment/layouts/dashboard-layout'
import useAuth from 'src/enviroment/hooks/useAuth'
import ProtectedRoute from './protected-route'
import { ROUTES } from 'src/types/routes'
import RequestSentPage from 'src/pages/auth/request-sent'
import ForgotPasswordPage from 'src/pages/auth/forgot-password'
import ForgotPasswordSentPage from 'src/pages/auth/forgot-password-sent'
import SetNewPasswordPage from 'src/pages/auth/set-new-password'
import ProjectDetailsPage from 'src/pages/dashboard/project-details'
import { UserRole } from 'src/types/user'
import MyProfilePage from 'src/pages/dashboard/my-profile'
import LegalPage from 'src/pages/dashboard/legal'
import SuperAdminPage from 'src/pages/dashboard/super-admin'

const RootRedirect = () => {
  const { isAuthenticated } = useAuth()
  return isAuthenticated ? <Navigate to="/dashboard/projects" replace /> : <Navigate to="/auth/sign-in" replace />
}

const LogoutPage = () => {
  const { logout } = useAuth()
  logout()
  return <Navigate to="/auth/sign-in" replace />
}

const publicRoutes: RouteObject[] = [
  {
    path: ROUTES.HOME,
    element: <RootRedirect />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.SIGN_IN,
    element: <SignInPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.SIGN_UP,
    element: <SignUpPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.PERSONAL_INFO,
    element: <PersonalInfoPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.REQUEST_SENT,
    element: <RequestSentPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: <ForgotPasswordPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.FORGOT_PASSWORD_SENT,
    element: <ForgotPasswordSentPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.SET_NEW_PASSWORD,
    element: <SetNewPasswordPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.LOGOUT,
    element: <LogoutPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.LEGAL,
    element: <LegalPage />,
    errorElement: <ErrorPage />,
  },
]

const protectedRoutes: RouteObject[] = [
  {
    path: '/dashboard',
    element: (
      <ProtectedRoute>
        <DashboardLayout />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'projects',
        element: (
          <ProtectedRoute allowedRoles={[UserRole.ADMIN, UserRole.CONTRIBUTOR, UserRole.VIEWER]}>
            <Projects />
          </ProtectedRoute>
        ),
      },
      {
        path: 'profile',
        element: (
          <ProtectedRoute allowedRoles={[UserRole.ADMIN, UserRole.CONTRIBUTOR, UserRole.VIEWER]}>
            <MyProfilePage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'project/:id',
        element: (
          <ProtectedRoute allowedRoles={[UserRole.ADMIN, UserRole.CONTRIBUTOR, UserRole.VIEWER]}>
            <ProjectDetailsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'atlas',
        element: (
          <ProtectedRoute allowedRoles={[UserRole.ADMIN, UserRole.CONTRIBUTOR]}>
            <Atlas />
          </ProtectedRoute>
        ),
      },
      {
        path: 'team',
        element: (
          <ProtectedRoute allowedRoles={[UserRole.ADMIN]}>
            <Team />
          </ProtectedRoute>
        ),
      },
      {
        path: 'superadmin',
        element: (
          <ProtectedRoute allowedRoles={[UserRole.ADMIN]}>
            <SuperAdminPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '',
        element: <Navigate to="projects" replace />,
      },
    ],
  },
]

const routes: RouteObject[] = [...publicRoutes, ...protectedRoutes]

export function createRouter() {
  return createBrowserRouter(routes)
}
