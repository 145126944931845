import { useEffect, useRef, useCallback } from 'react'
import mapboxgl, { MapMouseEvent, Popup } from 'mapbox-gl'
import { useNavigate } from 'react-router-dom'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Project } from 'src/types/projects'
import { formatArea } from 'src/enviroment/lib/utils'

mapboxgl.accessToken = import.meta.env.VITE_APP_MAPBOX_ACCESS_TOKEN

interface MapboxMapProps {
  initialOptions?: Partial<mapboxgl.MapboxOptions>
  center?: mapboxgl.LngLatLike
  zoom?: number
  onMapLoad?: (map: mapboxgl.Map) => void
  onMapClick?: (event: MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] }) => void
  projects?: Project[]
}

const defaultOptions: Partial<mapboxgl.MapboxOptions> = {
  style: 'mapbox://styles/mapbox/dark-v11',
  center: [0, 0],
  zoom: 2,
  projection: 'globe',
}

const createPopupContent = (project: Project) => {
  const container = document.createElement('div')
  container.className = 'flex flex-col gap-4 p-4'

  const title = document.createElement('div')
  title.className = 'font-bold text-base text-light-green-50'
  title.textContent = project.name

  const image = document.createElement('img')
  image.src = project.thumbnailUrl || '/src/assets/img/project_placeholder.png'
  image.className = 'w-48 h-32 object-cover rounded'
  image.alt = project.name

  const detailsContainer = document.createElement('div')
  detailsContainer.className = 'space-y-2'

  // Location detail with icon
  const locationDiv = document.createElement('div')
  locationDiv.className = 'flex items-center gap-2 text-light-green-50'

  const locationIconSvg = `<svg width="16" height="16" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 11.5a2.5 2.5 0 1 0 0 -5a2.5 2.5 0 0 0 0 5z" /><path d="M12 2a8 8 0 0 0 -8 8c0 1.892 .402 3.13 1.5 4.5l4.5 5.5a3 3 0 0 0 4 0l4.5 -5.5c1.098 -1.37 1.5 -2.608 1.5 -4.5a8 8 0 0 0 -8 -8z" /></svg>`
  locationDiv.innerHTML = locationIconSvg

  const locationText = document.createElement('span')
  locationText.className = 'text-sm'
  locationText.textContent = project.location
  locationDiv.appendChild(locationText)

  // Area detail with icon
  const areaDiv = document.createElement('div')
  areaDiv.className = 'flex items-center gap-2 text-light-green-50'

  const rulerIconSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="tabler-icon tabler-icon-ruler h-4 w-4"><path d="M5 4h14a1 1 0 0 1 1 1v5a1 1 0 0 1 -1 1h-7a1 1 0 0 0 -1 1v7a1 1 0 0 1 -1 1h-5a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1"></path><path d="M4 8l2 0"></path><path d="M4 12l3 0"></path><path d="M4 16l2 0"></path><path d="M8 4l0 2"></path><path d="M12 4l0 3"></path><path d="M16 4l0 2"></path></svg>`
  areaDiv.innerHTML = rulerIconSvg

  const areaText = document.createElement('span')
  areaText.className = 'text-sm'
  areaText.textContent = formatArea(project.totalAreaHectares)
  areaDiv.appendChild(areaText)

  detailsContainer.appendChild(locationDiv)
  detailsContainer.appendChild(areaDiv)

  container.appendChild(title)
  container.appendChild(image)
  container.appendChild(detailsContainer)

  return container
}

const MapboxMap: React.FC<MapboxMapProps> = ({
  initialOptions,
  onMapLoad,
  onMapClick,
  center,
  zoom,
  projects = [],
}) => {
  const navigate = useNavigate()
  const mapContainer = useRef<HTMLDivElement | null>(null)
  const mapInstance = useRef<mapboxgl.Map | null>(null)
  const popupRef = useRef<mapboxgl.Popup | null>(null)

  const getProjectFeatures = useCallback((projectsData: Project[]) => {
    if (!Array.isArray(projectsData)) {
      console.warn('Projects data is not an array:', projectsData)
      return []
    }

    return projectsData
      .filter((project) => project && typeof project.longitude === 'number' && typeof project.latitude === 'number')
      .map((project) => ({
        type: 'Feature' as const,
        geometry: {
          type: 'Point' as const,
          coordinates: [project.longitude, project.latitude],
        },
        properties: project,
      }))
  }, [])

  const initializeMap = useCallback(() => {
    if (!mapContainer.current || mapInstance.current) return

    mapInstance.current = new mapboxgl.Map({
      container: mapContainer.current,
      ...defaultOptions,
      ...initialOptions,
      center: center || defaultOptions.center,
      zoom: zoom || defaultOptions.zoom,
    })

    mapInstance.current.addControl(new mapboxgl.NavigationControl(), 'top-right')
    mapInstance.current.addControl(new mapboxgl.FullscreenControl())
    mapInstance.current.addControl(new mapboxgl.ScaleControl(), 'bottom-right')

    mapInstance.current.on('load', () => {
      if (!mapInstance.current) return

      // Add source with clustering enabled
      mapInstance.current.addSource('projects', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: getProjectFeatures(projects),
        },
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      })

      // Add cluster circles
      mapInstance.current.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'projects',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': '#2e846a',
          'circle-radius': ['step', ['get', 'point_count'], 20, 5, 30, 10, 40],
        },
      })

      // Add cluster count text
      mapInstance.current.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'projects',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        paint: {
          'text-color': '#ffffff',
        },
      })

      // Add unclustered point circles
      mapInstance.current.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'projects',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#2e846a',
          'circle-radius': 10,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#fff',
        },
      })

      // Handle hover on points
      mapInstance.current.on('mouseenter', 'unclustered-point', (e) => {
        if (!e.features?.[0] || !mapInstance.current) return

        const feature = e.features[0]
        const coordinates = (feature.geometry as { type: 'Point'; coordinates: [number, number] }).coordinates
        const project = feature.properties as unknown as Project

        mapInstance.current.getCanvas().style.cursor = 'pointer'

        popupRef.current = new Popup({
          closeButton: false,
          closeOnClick: false,
          offset: 25,
          className: 'project-popup',
        })
          .setLngLat(coordinates)
          .setDOMContent(createPopupContent(project))
          .addTo(mapInstance.current)
      })

      mapInstance.current.on('mouseleave', 'unclustered-point', () => {
        if (mapInstance.current) {
          mapInstance.current.getCanvas().style.cursor = ''
        }
        if (popupRef.current) {
          popupRef.current.remove()
        }
      })

      // Handle clicks
      mapInstance.current.on('click', 'unclustered-point', (e) => {
        if (!e.features?.[0]) return
        const project = e.features[0].properties as unknown as Project
        navigate(`/dashboard/project/${project.id}`)
      })

      mapInstance.current.on('click', 'clusters', (e) => {
        if (!e.features?.[0] || !mapInstance.current) return

        const features = mapInstance.current.queryRenderedFeatures(e.point, { layers: ['clusters'] })
        const clusterId = features?.[0]?.properties?.cluster_id
        if (!clusterId) return

        const source = mapInstance.current.getSource('projects') as mapboxgl.GeoJSONSource
        source.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err || !mapInstance.current) return

          mapInstance.current.easeTo({
            center: (e.features?.[0]?.geometry as any).coordinates,
            zoom: zoom as number,
          })
        })
      })

      if (onMapLoad) {
        onMapLoad(mapInstance.current)
      }
    })

    if (onMapClick) {
      mapInstance.current.on('click', onMapClick)
    }
  }, [initialOptions, onMapLoad, onMapClick, center, zoom, projects, getProjectFeatures, navigate])

  useEffect(() => {
    initializeMap()

    return () => {
      if (mapInstance.current) {
        if (popupRef.current) {
          popupRef.current.remove()
        }
        mapInstance.current.remove()
        mapInstance.current = null
      }
    }
  }, [initializeMap])

  useEffect(() => {
    if (mapInstance.current && mapInstance.current.getSource('projects')) {
      ;(mapInstance.current.getSource('projects') as mapboxgl.GeoJSONSource).setData({
        type: 'FeatureCollection',
        features: getProjectFeatures(projects),
      })
    }
  }, [projects, getProjectFeatures])

  return (
    <>
      <div ref={mapContainer} className="h-full w-full" />
      <style>{`
        .project-popup .mapboxgl-popup-content {
          background-color: rgba(0, 0, 0, 0.8);
          color: white;
          border-radius: 8px;
          padding: 12px;
        }
        .project-popup .mapboxgl-popup-tip {
          border-top-color: rgba(0, 0, 0, 0.8);
        }
      `}</style>
    </>
  )
}

export default MapboxMap
