import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { client } from '../axios'
import { handleApiError } from '../errorHandler'
import { STORAGE_KEYS } from 'src/enviroment/constants/storageKeys'
import { useTenantStore } from 'src/enviroment/store/tenentStore'
import { ITenant } from 'src/types/user'

export const tenantsQueryKeys = {
  tenants: ['tenants'] as const,
}
export const useGetTenants = () => {
  const { currentTenant, setCurrentTenant } = useTenantStore()

  return useQuery({
    queryKey: tenantsQueryKeys.tenants,
    queryFn: async () => {
      const { data } = await client.get('/tenants:myself')
      return data
    },
    enabled: Boolean(
      localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) || sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN),
    ),
    onSuccess: (data) => {
      // Assuming data is an array of tenants
      if (currentTenant?.id) {
        const updatedTenant = data.find((tenant: ITenant) => tenant.id === currentTenant.id)
        if (updatedTenant && JSON.stringify(updatedTenant) !== JSON.stringify(currentTenant)) {
          setCurrentTenant(updatedTenant)
        }
      }
    },
    onError: handleApiError,
  })
}
export const useGetTenant = (id: string) => {
  return useQuery({
    queryKey: [...tenantsQueryKeys.tenants, id],
    queryFn: async () => {
      const { data } = await client.get(`/tenants/${id}`)
      return data
    },
    onError: handleApiError,
  })
}

export const useUploadTenantLogo = () => {
  const query = useQueryClient()
  const selectedTenant = useTenantStore((state) => state.currentTenant.id)
  return useMutation({
    mutationFn: async (file: File) => {
      const formData = new FormData()
      formData.append('file', file)
      const { data } = await client.post(`/tenants/${selectedTenant}:logo`, formData)
      return data
    },
    onError: handleApiError,
    onSettled(data, error, variables, context) {
      if (!error) {
        useTenantStore.setState({
          currentTenant: {
            ...useTenantStore.getState().currentTenant,
            logoUrl: data.logoUrl,
          },
        })
        query.invalidateQueries(tenantsQueryKeys.tenants)
      }
    },
  })
}
