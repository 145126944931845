import React from 'react'
import Typography from '../atoms/Typography'

export interface TermsPrivacyProps {
  className?: string
}

const TermsPrivacy: React.FC<TermsPrivacyProps> = ({ className }) => {
  return (
    <div className="space-y-1 text-center text-light-green-50">
      <Typography variant="base" className="inline">
        By continuing you agree to the{' '}
        <Typography
          variant="base-semibold"
          className="inline cursor-pointer text-light-green-500 decoration-1 hover:text-light-green-400"
        >
          terms of service
        </Typography>
      </Typography>

      <Typography variant="base" className="inline">
        {' '}
        and{' '}
        <Typography
          variant="base-semibold"
          className=" inline cursor-pointer text-light-green-500  decoration-1 hover:text-light-green-400"
        >
          privacy policy
        </Typography>
        .
      </Typography>
    </div>
  )
}

export default TermsPrivacy
