import React, { useState, useRef, useEffect } from 'react'
import Typography from './Typography'
import { IconChevronDown } from '@tabler/icons-react'
import { cn } from 'src/enviroment/lib/utils'
import { ErrorMessage } from './ErrorMessage'

interface DropDownOption {
  id: string
  label: string
  value: string
}

interface DropDownProps {
  label?: string
  placeholder?: string
  options: DropDownOption[]
  value?: string[] | string
  onChange?: (values: string[] | string) => void
  errorMessage?: string
  className?: string
  labelClassName?: string
  multiple?: boolean
}

// Global state to track open dropdown
let openDropdownId: string | null = null

const DropDown = ({
  label,
  placeholder = 'Select one',
  options,
  value = [],
  onChange,
  errorMessage,
  className,
  labelClassName,
  multiple = false,
}: DropDownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const dropdownId = useRef(`dropdown-${Math.random()}`).current

  const selectedLabels = options
    .filter((option) => value.includes(option.value))
    .map((option) => option.label)
    .join(', ')

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleOpen = () => {
    if (openDropdownId && openDropdownId !== dropdownId) {
      // Close other dropdowns
      document.dispatchEvent(
        new CustomEvent('closeDropdowns', {
          detail: { except: dropdownId },
        }),
      )
    }
    openDropdownId = dropdownId
    setIsOpen(true)
  }

  useEffect(() => {
    const handleCloseDropdowns = (e: CustomEvent) => {
      if (e.detail.except !== dropdownId) {
        setIsOpen(false)
      }
    }
    document.addEventListener('closeDropdowns', handleCloseDropdowns as EventListener)
    return () => document.removeEventListener('closeDropdowns', handleCloseDropdowns as EventListener)
  }, [dropdownId])

  const handleOptionClick = (optionValue: string) => {
    if (multiple) {
      if (Array.isArray(value)) {
        const newValues = value.includes(optionValue) ? value.filter((v) => v !== optionValue) : [...value, optionValue]
        onChange?.(newValues)
      }
    } else {
      onChange?.(optionValue)
      setIsOpen(false)
      openDropdownId = null
    }
  }

  return (
    <div className="space-y-2">
      {label && (
        <Typography variant="sm-medium" element="label" className={cn('block text-gray-50', labelClassName)}>
          {label}
        </Typography>
      )}

      <div className="relative" ref={dropdownRef}>
        <button
          type="button"
          className={cn(
            'flex h-12 w-full items-center justify-between rounded-lg bg-gray-800 px-4 py-2 text-base text-gray-50',
            'border border-gray-300',
            'placeholder:text-gray-50',
            'transition-colors duration-200',
            'disabled:cursor-not-allowed disabled:opacity-50',
            errorMessage
              ? ['border-[#D71B3D]', 'focus:border-[#D71B3D]']
              : ['hover:border-gray-400', 'focus:border-light-green-500'],
            className,
          )}
          onClick={() => (isOpen ? setIsOpen(false) : handleOpen())}
        >
          <span className="line-clamp-1 text-left font-inter text-base">{selectedLabels || placeholder}</span>
          <IconChevronDown className={cn('min-w-5 h-5 w-5 transition-transform', isOpen && 'rotate-180')} />
        </button>

        {isOpen && (
          <div className="absolute z-50 mt-2 w-full rounded-md border border-gray-300 bg-gray-800 py-2 shadow-lg">
            {options.map((option) => (
              <button
                key={option.id}
                type="button"
                className={cn(
                  'flex w-full items-center justify-between px-4 py-3',
                  'hover:bg-dark-green-400',
                  value.includes(option.value) && 'bg-gray-900',
                )}
                onClick={() => handleOptionClick(option.value)}
              >
                <span className="text-gray-50">{option.label}</span>
                <div
                  className={cn(
                    'h-4 w-4 rounded-full border-2',
                    value.includes(option.value) ? 'border-light-green-500 bg-gray-50' : 'border-gray-400',
                  )}
                />
              </button>
            ))}
          </div>
        )}
      </div>
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  )
}

export default DropDown
