import { ColumnDef } from '@tanstack/react-table'
import DeleteAllMembersDialog from 'src/components/organisms/DeleteAllMembersDialog'
import DeleteMemberDialog from 'src/components/organisms/DeleteMemberDialog'
import { formatDate } from 'src/enviroment/lib/formatters/format-date'
import { IMember, IUser } from 'src/types/user'
import RoleCell from './cell/RoleCell'
import EditMemberDialog from 'src/components/organisms/EditMemberDialog'
import useAuth from 'src/enviroment/hooks/useAuth'

export type Member = IMember

export const useColumns = () => {
  const { user } = useAuth()

  const columns: ColumnDef<Member>[] = [
    {
      id: 'fullName',
      accessorFn: ({ firstName, lastName }) => `${firstName} ${lastName}`,
      header: 'Name',
      cell: ({
        row: {
          original: { firstName, lastName },
        },
      }) => (
        <span className="font-bold">
          {firstName} {lastName}
        </span>
      ),
    },
    {
      accessorKey: 'email',
      header: 'Email',
    },
    {
      accessorKey: 'company',
      header: 'Company',
    },
    {
      accessorKey: 'role',
      header: 'Role',
      cell: ({ row }) => <RoleCell member={row.original} />,
    },
    {
      accessorKey: 'lastLogin',
      header: 'Last Login',
      cell: ({ row }) => formatDate(row.original.lastLoginAt),
    },
    {
      id: 'actions',
      // header: () => (
      //   <div className="flex justify-center">
      //     <DeleteAllMembersDialog />
      //   </div>
      // ),
      cell: ({ row: { original } }) => {
        // Don't render action buttons if this is the current user
        if (user?.id === original.id) {
          return null
        }

        return (
          <div className="flex items-center gap-5 justify-self-center">
            <EditMemberDialog member={original} />
            <DeleteMemberDialog member={original} />
          </div>
        )
      },
    },
  ]

  return columns
}
