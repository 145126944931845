import * as React from 'react'
import { SVGProps } from 'react'

const LogoSymbol = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="32" fill="none" viewBox="0 0 18 32" {...props}>
    <g id="Symbol Light Green 2" clipPath="url(#clip0_1586_45307)">
      <path
        id="Vector"
        fill="#329175"
        fillRule="evenodd"
        d="M6.689 0h4.013v5.333a2.67 2.67 0 0 1 2.676-2.666h4.013v1.935c0 1.14-.924 2.065-2.064 2.065h-1.95a2.67 2.67 0 0 0-2.675 2.666V20a2.67 2.67 0 0 1 2.676-2.667h4.013v1.936c0 1.14-.924 2.064-2.064 2.064h-1.95A2.67 2.67 0 0 0 10.703 24v8H6.69a2.67 2.67 0 0 0-2.676-2.667H2.065A2.065 2.065 0 0 1 0 27.27v-1.936h4.013A2.67 2.67 0 0 1 6.69 28V17.333a2.67 2.67 0 0 0-2.676-2.666H2.065A2.065 2.065 0 0 1 0 12.602v-1.935h4.013a2.67 2.67 0 0 1 2.676 2.666zM5.351 5.333A2.67 2.67 0 0 1 2.676 8 2.67 2.67 0 0 1 0 5.333a2.67 2.67 0 0 1 2.676-2.666A2.67 2.67 0 0 1 5.35 5.333M2.676 22.667A2.67 2.67 0 0 0 5.35 20a2.67 2.67 0 0 0-2.675-2.667A2.67 2.67 0 0 0 0 20a2.67 2.67 0 0 0 2.676 2.667M17.39 12a2.67 2.67 0 0 1-2.675 2.667A2.67 2.67 0 0 1 12.04 12a2.67 2.67 0 0 1 2.676-2.667A2.67 2.67 0 0 1 17.39 12m-2.675 17.333a2.67 2.67 0 0 0 2.675-2.666A2.67 2.67 0 0 0 14.716 24a2.67 2.67 0 0 0-2.676 2.667 2.67 2.67 0 0 0 2.676 2.666"
        clipRule="evenodd"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_1586_45307">
        <path fill="#fff" d="M0 0h17.6v32H0z"></path>
      </clipPath>
    </defs>
  </svg>
)

export default LogoSymbol
