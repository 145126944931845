import React from 'react'
import Typography from 'src/components/atoms/Typography'
import { IconMapPin, IconRuler } from '@tabler/icons-react'
import { Project } from 'src/types/projects'
import { SecondaryButton } from '../atoms/buttons/SecondaryButton'
import useAuth from 'src/enviroment/hooks/useAuth'
import DeleteProjectDialog from '../organisms/DeleteProjectDialog'
import Placeholder from 'src/assets/img/project_placeholder.png'
import { UserRole } from 'src/types/user'
import { formatArea } from 'src/enviroment/lib/utils'
interface ProjectCardProps {
  project: Project
  onOpen?: (project: Project) => void
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project, onOpen }) => {
  const { userRole } = useAuth()

  const isDeleteAllowed = userRole === UserRole.ADMIN || userRole === UserRole.CONTRIBUTOR
  return (
    <div className="relative overflow-hidden rounded-lg bg-gray-800">
      <img src={project.thumbnailUrl || Placeholder} alt={project.name} className="h-40 w-full object-cover" />
      <div className="space-y-4 p-4">
        <Typography variant="base-bold" className="text-light-green-50">
          {project.name}
        </Typography>

        <div className="space-y-2">
          <div className="flex items-center gap-2 text-light-green-50">
            <IconMapPin className="h-4 w-4" />
            <Typography variant="sm">{project.location}</Typography>
          </div>

          <div className="flex items-center gap-2 text-light-green-50">
            <IconRuler className="h-4 w-4" />
            <Typography variant="sm">{formatArea(project.totalAreaHectares)}</Typography>
          </div>
        </div>

        <SecondaryButton className="w-full" onClick={() => onOpen?.(project)}>
          Open Project
        </SecondaryButton>
        {/* {isDeleteAllowed && <DeleteProjectDialog project={project} />} */}
      </div>
    </div>
  )
}
export default ProjectCard
