import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { Input } from 'src/components/atoms/inputs/Input'
import { PrimaryButton } from 'src/components/atoms/buttons/PrimaryButton'
import { SecondaryButton } from 'src/components/atoms/buttons/SecondaryButton'
import Typography from 'src/components/atoms/Typography'
import { password } from 'src/enviroment/validation/fields'

const changePasswordSchema = z
  .object({
    oldPassword: password,
    newPassword: password,
    confirmNewPassword: z.string().min(1, 'Please confirm your password'),
  })
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    message: "Passwords don't match",
    path: ['confirmNewPassword'],
  })

type ChangePasswordFormData = z.infer<typeof changePasswordSchema>

interface ChangePasswordFormProps {
  isLoading: boolean
  onSubmit: (data: ChangePasswordFormData) => Promise<void>
  onCancel: () => void
}

export default function ChangePasswordForm({ isLoading, onSubmit, onCancel }: ChangePasswordFormProps) {
  const methods = useForm<ChangePasswordFormData>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    resolver: zodResolver(changePasswordSchema),
  })

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = methods

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-w-2xl space-y-6">
      <div className="mb-8">
        <Typography variant="xl" className="text-light-green-50">
          Change Password
        </Typography>
      </div>

      <Controller
        name="oldPassword"
        control={control}
        render={({ field }) => (
          <Input
            label="Old Password"
            type="password"
            errorMessage={errors.oldPassword?.message}
            {...field}
            className="border-gray-700 bg-transparent text-gray-100"
          />
        )}
      />

      <Controller
        name="newPassword"
        control={control}
        render={({ field }) => (
          <Input
            label="New Password"
            type="password"
            errorMessage={errors.newPassword?.message}
            {...field}
            className="border-gray-700 bg-transparent text-gray-100"
          />
        )}
      />

      <Controller
        name="confirmNewPassword"
        control={control}
        render={({ field }) => (
          <Input
            label="Confirm Password"
            type="password"
            errorMessage={errors.confirmNewPassword?.message}
            {...field}
            className="border-gray-700 bg-transparent text-gray-100"
          />
        )}
      />

      <div className="flex justify-end space-x-4 pt-4">
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton type="submit" disabled={!isValid} isLoading={isLoading}>
          Save Changes
        </PrimaryButton>
      </div>
    </form>
  )
}
