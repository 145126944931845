import React, { useEffect, useState } from 'react'
import AddAttachmentButton from '../atoms/buttons/AddAttachmentButton'
import FileUploadStatus from '../molecules/FileUploadStatus'

interface FileUploadContainerProps {
  file: File | null
  onFileSelect: (file: File) => void
  onFileRemove: () => void
}

const FileUploadContainer: React.FC<FileUploadContainerProps> = ({ file, onFileSelect, onFileRemove }) => {
  const [uploadProgress, setUploadProgress] = useState<number>(0)

  useEffect(() => {
    if (!file) return

    setUploadProgress(0)
    const duration = 1000
    const interval = 100
    const steps = duration / interval
    const increment = 100 / steps

    let progress = 0
    const timer = setInterval(() => {
      progress += increment
      setUploadProgress((prev) => Math.min(Math.round(progress), 100))

      if (progress >= 100) {
        clearInterval(timer)
      }
    }, interval)

    return () => {
      clearInterval(timer)
    }
  }, [file])

  return file ? (
    <FileUploadStatus file={file} progress={uploadProgress} onRemove={onFileRemove} />
  ) : (
    <AddAttachmentButton onFileSelect={onFileSelect} />
  )
}

export default FileUploadContainer
