import React, { useState } from 'react'
import { IconChevronDown, IconFilter, IconX } from '@tabler/icons-react'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/atoms/dialog'
import Typography from '../atoms/Typography'

interface FilterButtonProps {
  onFilterChange: (filter: 'approved' | 'not_approved' | null) => void
  initialFilter?: 'approved' | 'not_approved' | null
}

const FilterButton: React.FC<FilterButtonProps> = ({ onFilterChange, initialFilter = null }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState<'approved' | 'not_approved' | null>(initialFilter)
  const [tempFilter, setTempFilter] = useState<'approved' | 'not_approved' | null>(initialFilter)

  const handleClose = () => {
    setIsOpen(false)
    setTempFilter(selectedFilter)
  }

  const handleSave = () => {
    setSelectedFilter(tempFilter)
    onFilterChange(tempFilter)
    handleClose()
  }

  const handleClearFilter = (e: React.MouseEvent) => {
    e.stopPropagation()
    setSelectedFilter(null)
    onFilterChange(null)
  }

  const getFilterLabel = () => {
    switch (tempFilter) {
      case 'approved':
        return 'Approved'
      case 'not_approved':
        return 'Not Approved'
      default:
        return 'Select status'
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <button className="flex min-h-[50px] w-full items-center gap-2 rounded-lg border border-gray-300 bg-gray-800 px-3 py-2 text-gray-50 transition-colors hover:border-gray-400">
          <IconFilter className="h-4 w-4" />
          <Typography variant="base" className="text-gray-200">
            Filter
          </Typography>
          {selectedFilter && (
            <>
              <div className="h-5 w-[1px] bg-gray-300" />
              <div className="flex items-center gap-2 rounded-md bg-light-green-500 px-2 py-1">
                <Typography variant="sm-medium" className="text-gray-100">
                  {selectedFilter === 'approved' ? 'Approved' : 'Not Approved'}
                </Typography>
                <IconX
                  className="h-3 w-3 cursor-pointer text-gray-50 hover:text-light-green-400"
                  onClick={handleClearFilter}
                />
              </div>
            </>
          )}
        </button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>
            <Typography variant="xl">Filter</Typography>
          </DialogTitle>
        </DialogHeader>

        <div className="mt-4 space-y-2">
          <div className="relative">
            <button
              type="button"
              className="flex h-12 w-full items-center justify-between rounded-lg border border-gray-300 bg-gray-800 px-4 py-2 text-gray-50 transition-colors hover:border-gray-400"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <span>{getFilterLabel()}</span>
              <IconChevronDown className={`h-5 w-5 transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`} />
            </button>

            {isDropdownOpen && (
              <div className="absolute z-50 mt-2 w-full rounded-md border border-gray-300 bg-gray-800 py-2 shadow-lg">
                {[
                  { value: 'approved', label: 'Approved' },
                  { value: 'not_approved', label: 'Not Approved' },
                ].map((option) => (
                  <button
                    key={option.value}
                    type="button"
                    className="flex w-full items-center justify-between px-4 py-3 hover:bg-gray-700"
                    onClick={() => {
                      setTempFilter(option.value as 'approved' | 'not_approved')
                      setIsDropdownOpen(false)
                    }}
                  >
                    <span className="text-gray-50">{option.label}</span>
                    <div
                      className={`h-4 w-4 rounded-full border-2 ${
                        tempFilter === option.value ? 'border-light-green-500 bg-gray-50' : 'border-gray-400'
                      }`}
                    />
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>

        <DialogFooter className="mt-6 flex justify-between sm:justify-between">
          <button
            onClick={handleClose}
            className="rounded-lg border border-gray-300 px-8 py-2 text-light-green-500 hover:bg-gray-700"
          >
            Back
          </button>
          <button
            onClick={handleSave}
            className="rounded-lg bg-light-green-500 px-8 py-2 text-white hover:bg-light-green-400"
          >
            Save
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default FilterButton
