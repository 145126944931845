import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './styles/globals.less'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://c499f792f972be7257b95b6fb3d61930@o4508245379973120.ingest.us.sentry.io/4508245382660096',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  enabled: import.meta.env.DEV ? false : true,
  environment: import.meta.env.VITE_APP_MODE,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('Failed to find the root element')

const root = ReactDOM.createRoot(rootElement)
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)
