import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import Typography from './Typography'
import * as Tooltip from '@radix-ui/react-tooltip'

interface NavLinkProps extends LinkProps {
  icon?: React.ReactNode
  isActive?: boolean
  isCollapsed?: boolean
  children: React.ReactNode
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

const NavLink: React.FC<NavLinkProps> = ({
  icon,
  isActive = false,
  isCollapsed = false,
  children,
  className = '',
  onClick,
  ...props
}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      e.preventDefault()
      onClick(e)
    }
  }

  const content = (
    <Link
      className={`flex items-center ${isCollapsed ? 'justify-center' : 'gap-3'} rounded-md p-2 transition-colors ${
        isActive ? 'bg-dark-green-400' : 'text-light-green-50 hover:bg-green-50 hover:text-accent-foreground'
      } ${className}`}
      onClick={handleClick}
      {...props}
    >
      {icon}
      {!isCollapsed && <Typography variant="base">{children}</Typography>}
    </Link>
  )

  if (isCollapsed) {
    return (
      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>{content}</Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content
              className="z-50 rounded-[12px] bg-gray-700 px-6 py-2 text-base text-white animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95"
              side="right"
              sideOffset={5}
            >
              {children}
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    )
  }

  return content
}

export default NavLink
