import { ComponentProps, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useResetPassword } from 'src/enviroment/api/services/authApi'
import SetNewPasswordTemplate from 'src/templates/auth/forgot-password/SetNewPasswordTemplate'
import { ROUTES } from 'src/types/routes'

export default function SetNewPasswordPage() {
  const navigate = useNavigate()
  const token = new URLSearchParams(window.location.search).get('token') as string
  const { mutateAsync } = useResetPassword()
  // http://localhost:5001/auth/set-new-password?token=123

  const handleSubmit: ComponentProps<typeof SetNewPasswordTemplate>['onSubmit'] = async ({
    password,
    confirmPassword,
  }) => {
    try {
      mutateAsync({ token, password, confirmPassword })
      navigate(ROUTES.HOME)
    } catch (error) {
      // handle
    }
  }
  const goBack = useCallback(() => {
    navigate(ROUTES.SIGN_IN)
  }, [navigate])

  return <SetNewPasswordTemplate onSubmit={handleSubmit} isLoading={false} goBack={goBack} />
}
