import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { Input } from 'src/components/atoms/inputs/Input'
import { PrimaryButton } from 'src/components/atoms/buttons/PrimaryButton'
import { SecondaryButton } from 'src/components/atoms/buttons/SecondaryButton'

const editProfileSchema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  company: z.string().min(1, 'Company name is required'),
})

type EditProfileFormData = z.infer<typeof editProfileSchema>

interface EditProfileFormProps {
  initialData: {
    firstName: string
    lastName: string
    company: string
  }
  isLoading: boolean
  onSubmit: (data: EditProfileFormData) => void
  onCancel: () => void
}

export default function EditProfileForm({ initialData, isLoading, onSubmit, onCancel }: EditProfileFormProps) {
  const methods = useForm<EditProfileFormData>({
    defaultValues: initialData,
    resolver: zodResolver(editProfileSchema),
  })

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = methods

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-w-2xl space-y-6">
      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <Input
            label="First Name"
            {...field}
            errorMessage={errors.firstName?.message}
            className="border-gray-700 bg-transparent text-gray-100"
          />
        )}
      />

      <Controller
        name="lastName"
        control={control}
        render={({ field }) => (
          <Input
            label="Last Name"
            {...field}
            errorMessage={errors.lastName?.message}
            className="border-gray-700 bg-transparent text-gray-100"
          />
        )}
      />

      <Controller
        name="company"
        control={control}
        render={({ field }) => (
          <Input
            label="Company"
            {...field}
            errorMessage={errors.company?.message}
            className="border-gray-700 bg-transparent text-gray-100"
          />
        )}
      />

      <div className="flex justify-end space-x-4 pt-4">
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton type="submit" disabled={!isValid} isLoading={isLoading}>
          Save Changes
        </PrimaryButton>
      </div>
    </form>
  )
}
