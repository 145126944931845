import React, { useCallback } from 'react'
import { IconPaperclip } from '@tabler/icons-react'

interface AddAttachmentButtonProps {
  onFileSelect: (file: File) => void
}

const AddAttachmentButton: React.FC<AddAttachmentButtonProps> = ({ onFileSelect }) => {
  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = e.target.files?.[0]
      if (selectedFile) {
        onFileSelect(selectedFile)
      }
    },
    [onFileSelect],
  )

  return (
    <div className="inline-flex">
      <input
        type="file"
        id="file-upload"
        className="hidden"
        onChange={handleFileChange}
        accept=".json,.geojson,.jpg,.jpeg,.png,.pdf,.png"
      />
      <label
        htmlFor="file-upload"
        className="inline-flex cursor-pointer items-center gap-2 text-light-green-50 hover:text-light-green-100"
      >
        <IconPaperclip className="h-6 w-6" />

        <span className="text-sm">Add attachment</span>
      </label>
    </div>
  )
}

export default AddAttachmentButton
