import React, { useState, useEffect, useCallback } from 'react'

interface ResendTimerProps {
  /**
   * Initial time in seconds for the countdown
   */
  initialTime?: number
  /**
   * Callback function called when timer reaches zero
   */
  onTimerComplete?: () => void
  /**
   * Render prop child function that receives current state
   */
  children: (timeLeft: number, startTimer: () => void, isActive: boolean) => React.ReactElement | null
}

const ResendTimer: React.FC<ResendTimerProps> = ({ initialTime = 60, onTimerComplete, children }) => {
  // Track remaining time in seconds
  const [timeLeft, setTimeLeft] = useState<number>(0)

  // Handle countdown logic
  useEffect(() => {
    let timer: NodeJS.Timeout

    if (timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          // Check if timer is complete
          if (prevTime <= 1 && onTimerComplete) {
            onTimerComplete()
          }
          return prevTime - 1
        })
      }, 1000)
    }

    // Cleanup timer on unmount or when timeLeft changes
    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [timeLeft, onTimerComplete])

  // Start/restart timer function
  const startTimer = useCallback(() => {
    setTimeLeft(initialTime)
  }, [initialTime])

  // Render child function with current state
  return children(timeLeft, startTimer, timeLeft > 0)
}

export default ResendTimer
