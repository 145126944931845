import React from 'react'
import { Helmet } from 'react-helmet-async'
import AuthWrapper from 'src/enviroment/layouts/auth-wrapper'
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { z } from 'zod'
import { createPasswordSchema, forgotPasswordSchema } from 'src/enviroment/validation/schema'
import Typography from 'src/components/atoms/Typography'
import { Input } from 'src/components/atoms/inputs/Input'
import { PrimaryButton } from 'src/components/atoms/buttons/PrimaryButton'
import { SecondaryButton } from 'src/components/atoms/buttons/SecondaryButton'

type ForgotPasswordFormData = z.infer<typeof createPasswordSchema>

const defaultValues = { password: '', confirmPassword: '' } satisfies ForgotPasswordFormData

export interface SetNewPasswordTemplateProps {
  onSubmit: SubmitHandler<ForgotPasswordFormData>
  goBack: () => void
  isLoading?: boolean
}

const SetNewPasswordTemplate: React.FC<SetNewPasswordTemplateProps> = ({ onSubmit, goBack, isLoading = false }) => {
  const methods = useForm<ForgotPasswordFormData>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(createPasswordSchema),
  })

  const {
    handleSubmit,
    formState: { isValid },
  } = methods

  return (
    <FormProvider {...methods}>
      <Helmet>
        <title>RAMO - Set new Password</title>
      </Helmet>
      <AuthWrapper>
        <div className="space-y-8">
          <div className="space-y-8 text-center">
            <Typography variant="4xl">Set a new password</Typography>
            <div>
              <Typography variant="base" className="text-center">
                Please note that the new password cannot be the same as the old one
              </Typography>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <Controller
              name="password"
              control={methods.control}
              render={({ field: { onChange, ...field }, formState }) => (
                <Input
                  {...field}
                  onChange={onChange}
                  label="New password"
                  autoFocus
                  type="password"
                  errorMessage={formState.errors.password?.message}
                />
              )}
            />
            <Controller
              name="confirmPassword"
              control={methods.control}
              render={({ field: { onChange, ...field }, formState }) => (
                <Input
                  {...field}
                  onChange={onChange}
                  label="Confirm password"
                  type="password"
                  errorMessage={formState.errors.confirmPassword?.message}
                />
              )}
            />

            <div className="space-y-4">
              <PrimaryButton type="submit" isLoading={isLoading} disabled={!isValid} fullWidth>
                Confirm
              </PrimaryButton>
              <SecondaryButton
                onClick={goBack}
                type="button"
                fullWidth
                className="text-light-green-500 hover:text-light-green-400"
              >
                Cancel
              </SecondaryButton>
            </div>
          </form>
        </div>
      </AuthWrapper>
    </FormProvider>
  )
}

export default SetNewPasswordTemplate
