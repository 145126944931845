import React, { forwardRef } from 'react'
import { cn } from 'src/enviroment/lib/utils'
import Typography from '../Typography'
import { ErrorMessage } from '../ErrorMessage'

export interface TextAreaProps extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'className'> {
  icon?: React.ReactNode
  label?: string
  errorMessage?: string
  className?: string
  labelClassName?: string
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, icon, errorMessage, label, labelClassName, rows = 4, ...props }, ref) => {
    return (
      <div className="space-y-2">
        {label && (
          <Typography variant="sm-medium" element="label" className={cn('block text-gray-50', labelClassName)}>
            {label}
          </Typography>
        )}
        <div className="relative">
          <textarea
            rows={rows}
            className={cn(
              'flex w-full rounded-lg bg-gray-800 px-4 py-3 text-base text-gray-50',
              'border border-gray-300',
              'placeholder:text-gray-200',
              'focus-visible:outline-none',
              'transition-colors duration-200',
              'disabled:cursor-not-allowed disabled:opacity-50',
              'resize-none',
              errorMessage
                ? ['border-[#D71B3D]', 'focus:border-[#D71B3D]']
                : ['hover:border-gray-400', 'focus:border-light-green-500'],
              icon && 'pl-12',
              className,
            )}
            ref={ref}
            {...props}
          />

          {icon && <div className="absolute left-4 top-6 -translate-y-1/2 text-gray-200">{icon}</div>}
        </div>
        {errorMessage && <ErrorMessage message={errorMessage} />}
      </div>
    )
  },
)

TextArea.displayName = 'TextArea'

export { TextArea }
