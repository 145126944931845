import React from 'react'
import NavLink from '../atoms/NavLink'

interface NavigationItem {
  to: string
  icon: React.ReactNode
  label: string
  isActive?: boolean
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

interface NavigationGroupProps {
  items: NavigationItem[]
  isCollapsed: boolean
}

const NavigationGroup: React.FC<NavigationGroupProps> = ({ items, isCollapsed }) => {
  return (
    <nav className="space-y-2">
      {items.map((item, index) => (
        <NavLink
          key={`${item.label + index + item.to}`}
          to={item.to}
          icon={item.icon}
          isCollapsed={isCollapsed}
          onClick={item.onClick}
          isActive={item.isActive}
        >
          {item.label}
        </NavLink>
      ))}
    </nav>
  )
}

export default NavigationGroup
