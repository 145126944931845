import { FC, ReactElement } from 'react'
import Typography from 'src/components/atoms/Typography'
import ProjectGrid from 'src/components/organisms/ProjectGrid'
import DashboardHeader from 'src/components/molecules/DashboardHeader'
import Loader from 'src/components/atoms/Loader'
import { Project } from 'src/types/projects'
import RequestProjectDialog from 'src/components/organisms/RequestProjectDialog'

interface ProjectsTemplateProps {
  projects: Project[]
  isLoading: boolean
  error: unknown
  isFetching: boolean
  onOpenProject: (project: Project) => void
}

const ProjectsTemplate: FC<ProjectsTemplateProps> = ({
  projects,
  isLoading,
  error,
  isFetching,
  onOpenProject,
}): ReactElement => {
  const renderLabel = (): ReactElement => {
    return (
      <div className="ml-8 mr-8 mt-5 flex flex-row items-center justify-between text-light-green-50">
        <Typography variant="xl-bold">Carbon Atlas</Typography>
      </div>
    )
  }

  return (
    <div>
      <DashboardHeader title="My Projects" rightSide={<RequestProjectDialog />} />
      {renderLabel()}
      {isLoading && (
        <div className="flex h-full items-center justify-center text-gray-50">
          <Loader />
        </div>
      )}

      {!isLoading && !isFetching && !error && projects.length === 0 && (
        <div className="mt-10 flex h-full flex-col items-center text-light-green-50">
          <Typography variant="base">You don&apos;t have any projects created yet.</Typography>
          <Typography variant="base">Create your first project, and it will immediately appear in this tab.</Typography>
        </div>
      )}

      {!isLoading && !error && projects.length > 0 && (
        <div className="space-y-8 p-8">
          <ProjectGrid projects={projects} onOpenProject={onOpenProject} />
        </div>
      )}
    </div>
  )
}

export default ProjectsTemplate
