import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../../components/organisms/SideBar'
import Footer from 'src/components/molecules/Footer'

const DashboardLayout: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)

  return (
    <div className="flex min-h-screen w-full">
      <Sidebar isCollapsed={isCollapsed} onCollapse={setIsCollapsed} />
      <div className={`flex flex-1 flex-col transition-all duration-300 ${isCollapsed ? 'ml-[88px]' : 'ml-64'}`}>
        <main className="flex-1 overflow-auto bg-background bg-gray-900">
          <Outlet />
        </main>
        <Footer />
      </div>
    </div>
  )
}

export default DashboardLayout
