import { AxiosError } from 'axios'
import { enqueueSnackbar } from 'notistack'

interface ApiErrorResponse {
  message: string
  error: string
  statusCode: number
}

export class ApiError extends Error {
  constructor(public message: string, public status?: number, public code?: string) {
    super(message)
    this.name = 'ApiError'
  }
}

export const handleApiError = (error: unknown) => {
  if (error instanceof AxiosError) {
    const response = error.response?.data as ApiErrorResponse
    const errorMessage = response?.message || 'An unexpected error occurred'
    enqueueSnackbar(errorMessage, { variant: 'error' })
    throw new ApiError(errorMessage, error.response?.status, response?.error)
  }
  const defaultError = 'An unexpected error occurred'
  enqueueSnackbar(defaultError, { variant: 'error' })
  throw new ApiError(defaultError)
}
