import { useMutation } from '@tanstack/react-query'
import { client } from '../axios'
import { handleApiError } from '../errorHandler'
import { SupportTicketResponse } from 'src/types/support'

export const supportQueryKeys = {
  tickets: ['tickets'] as const,
  ticket: (id: string) => ['ticket', id] as const,
}

interface CreateTicketParams {
  subject: string
  description: string
  file?: File
}

export const supportApi = {
  async createTicket(params: CreateTicketParams): Promise<SupportTicketResponse> {
    const formData = new FormData()
    formData.append('subject', params.subject)
    formData.append('description', params.description)

    if (params.file) {
      formData.append('file', params.file)
    }

    const { data } = await client.post('/support/help', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          console.log(`Upload progress: ${progress}%`)
        }
      },
    })
    return data
  },
}

export const useCreateSupportTicket = () => {
  return useMutation({
    mutationFn: (params: CreateTicketParams) => supportApi.createTicket(params),
    onError: handleApiError,
  })
}
