import React from 'react'
import { Paperclip, X } from 'lucide-react'

interface FileUploadStatusProps {
  file: File
  progress: number
  onRemove: () => void
}

const FileUploadStatus: React.FC<FileUploadStatusProps> = ({ file, progress, onRemove }) => {
  const fileSize = (file.size / (1024 * 1024)).toFixed(2) // Размер в МБ

  return (
    <div className="flex items-center rounded-lg bg-gray-800 p-3">
      <div className="flex flex-1 items-center gap-2">
        <div className="rounded-full bg-light-green-50/10 p-2">
          <Paperclip className="h-4 w-4 text-light-green-300" />
        </div>
        <div className="min-w-0 flex-1">
          <div>
            <p className="text-sm text-light-green-50">{file.name}</p>
            <p className="text-xs text-gray-400">{fileSize} MB</p>
          </div>
          {progress < 100 && (
            <div className="mt-2 h-1 w-full overflow-hidden rounded-full bg-gray-700">
              <div
                className="h-1 rounded-full bg-light-green-300 transition-all duration-300"
                style={{ width: `${progress}%` }}
              />
            </div>
          )}
        </div>
        <button
          type="button"
          onClick={onRemove}
          className="rounded-full p-1 hover:bg-gray-700"
          aria-label="Remove file"
        >
          <X className="h-4 w-4 text-gray-400" />
        </button>
      </div>
    </div>
  )
}

export default FileUploadStatus
