import { Helmet } from 'react-helmet-async'
import { useState } from 'react'
import { useMembersQuery } from 'src/enviroment/api/services/membersApi'
import { useUploadTenantLogo } from 'src/enviroment/api/services/tenantsApi'
import { useTenantStore } from 'src/enviroment/store/tenentStore'
import RAMOMembersTemplate from 'src/templates/members/RAMOMembersTemplate'

export default function TeamPage() {
  const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useMembersQuery()
  const currentTenant = useTenantStore((state) => state.currentTenant)
  const uploadLogo = useUploadTenantLogo()
  const [logoPreview, setLogoPreview] = useState<string | null>(currentTenant?.logoUrl || null)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const handleSearch = (value: string) => {
    setSearchTerm(value)
  }

  const handleRemoveLogo = () => {
    setLogoPreview(null)
  }

  const handleUpdateLogo = async (file: File) => {
    const { logoUrl } = await uploadLogo.mutateAsync(file)
    setLogoPreview(logoUrl)
  }

  const filteredMembers =
    data?.pages.flat().filter((member) => {
      if (!searchTerm) return true

      const lowercaseSearch = searchTerm.toLowerCase()
      return (
        member.email?.toLowerCase().includes(lowercaseSearch) ||
        member.firstName?.toLowerCase().includes(lowercaseSearch) ||
        member.lastName?.toLowerCase().includes(lowercaseSearch) ||
        member.company?.toLowerCase().includes(lowercaseSearch) ||
        member.tenantMemberAccess.role?.toLowerCase().includes(lowercaseSearch)
      )
    }) || []

  return (
    <>
      <Helmet>
        <title>RAMO - Team</title>
      </Helmet>
      <RAMOMembersTemplate
        // Workspace props
        logoPreview={logoPreview}
        onLogoSelect={handleUpdateLogo}
        onLogoRemove={handleRemoveLogo}
        // Members list props
        members={filteredMembers}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        onFetchNextPage={fetchNextPage}
        searchTerm={searchTerm}
        onSearch={handleSearch}
      />
    </>
  )
}
