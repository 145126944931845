import { z } from 'zod'
import { userTypes, userPurposes } from '../constants/constants'
const email = z.string().trim().email('Email is required ')

const isAgree = z.boolean().refine((value) => value === true, { message: 'You must agree to the terms and conditions' })
const password = z
  .string()
  .trim()
  .min(8, 'Password must be at least 8 characters long')
  .regex(/^(?=.*[A-Z])/, 'Password must include at least one uppercase letter')
  .regex(/^(?=.*[a-z])/, 'Password must include at least one lowercase letter')
  .regex(/^(?=.*\d)/, 'Password must include at least one number')
  .regex(/^(?=.*[!@#$%^&*])/, 'Password must include at least one special character (!@#$%^&*)')
  .regex(/^(?!.*\s)/, 'Password cannot contain spaces')

const firstName = z
  .string()
  .trim()
  .min(2, 'First name must be at least 2 characters long')
  .max(32, 'First name must be at most 32 characters long')

const lastName = z
  .string()
  .trim()
  .min(2, 'Last name must be at least 2 characters long')
  .max(32, 'Last name must be at most 32 characters long')

const companyName = z.string().trim().min(2, 'Company name must be at least 2 characters long')

const userTypesArray = userTypes.map((type) => type.value)
const purposesArray = userPurposes.map((purpose) => purpose.value)

const description = z.enum(userTypesArray as [string, ...string[]])
const purpose = z.enum(purposesArray as [string, ...string[]])

export { isAgree, email, password, firstName, lastName, companyName, description, purpose }
