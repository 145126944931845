import React, { useEffect, useRef } from 'react'
import { AlertDialog, AlertDialogContent, AlertDialogOverlay } from '@radix-ui/react-alert-dialog'

interface ModalContainerProps {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
}

const ModalContainer = ({ isOpen, onClose, children }: ModalContainerProps) => {
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (contentRef.current && !contentRef.current.contains(event.target as Node)) {
        onClose()
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, onClose])

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogOverlay className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
      <AlertDialogContent
        ref={contentRef}
        className="fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border border-gray-800 bg-gray-900 p-6 shadow-lg duration-200 sm:rounded-lg"
      >
        {children}
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default ModalContainer
