import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Project, ProjectNames } from 'src/types/projects'
import { client } from '../axios'
import { useTenantStore } from 'src/enviroment/store/tenentStore'

interface CreateProjectDTO {
  name: string
  files: File[]
}

interface CreateProjectResponse {
  id: string
  name: string
  location: string
  area: number
  image: string
}

export enum ProjectTags {
  List = 'ProjectList',
  AtlasProject = 'AtlasProjects',
  ProjectNames = 'ProjectNames',
  Detail = 'ProjectDetail',
}

export function useGetAtlasProjects() {
  const selectedTenantId = useTenantStore((state) => state.currentTenant?.id)

  return useQuery({
    queryKey: [ProjectTags.AtlasProject, selectedTenantId],
    queryFn: async () => {
      const response = await client.get<Project[]>(`/tenants/${selectedTenantId}/projects:atlas`)
      return response.data
    },
    enabled: Boolean(selectedTenantId),
  })
}

interface ExportLayerDTO {
  export_link: string
}

interface ExportLayerParams {
  projectId: string
  layerId: string
}

export function useGetLinkForExportDownload() {
  const selectedTenantId = useTenantStore((state) => state.currentTenant?.id)

  return useMutation({
    mutationFn: async ({ projectId, layerId }: ExportLayerParams) => {
      const response = await client.get<ExportLayerDTO>(
        `/tenants/${selectedTenantId}/projects/${projectId}/layers/${layerId}:export`,
      )
      window.open(response.data.export_link, '_blank')
      return response.data
    },
  })
}

export function useProjectsQuery(/* searchQuery: string */) {
  const selectedTenantId = useTenantStore((state) => state.currentTenant?.id)

  return useInfiniteQuery({
    queryKey: [ProjectTags.List, selectedTenantId],
    queryFn: async ({ pageParam: cursorId }) => {
      const response = await client.get<Project[]>(`/tenants/${selectedTenantId}/projects:filter`, {
        params: { cursorId, take: 20 },
      })

      return response.data
    },
    enabled: Boolean(selectedTenantId),
    getNextPageParam: (lastPage) => lastPage.at(-1)?.id,
  })
}

export function useProjectsNamesQuery() {
  const currentTenantId = useTenantStore((state) => state.currentTenant?.id)
  return useQuery({
    queryKey: [ProjectTags.ProjectNames],
    queryFn: async () => {
      const response = await client.get<ProjectNames[]>(`/tenants/${currentTenantId}/projects:all-names`)

      return response.data
    },
  })
}

export function useDeleteProjectMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (projectId: string) => {
      return new Promise((res) => setTimeout(res, 500)).then(() => client.delete('/'))
    },
    onMutate: async (projectId) => {
      await queryClient.cancelQueries({ queryKey: [ProjectTags.List] })

      const previousData = queryClient.getQueryData([ProjectTags.List])

      queryClient.setQueryData([ProjectTags.List], (old: any) => ({
        pages: old.pages.map((page: Project[]) => page.filter((project) => project.id !== projectId)),
        pageParams: old.pageParams,
      }))

      return { previousData }
    },
    onError: (_, __, context) => {
      queryClient.setQueryData([ProjectTags.List], context?.previousData)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [ProjectTags.List] })
    },
  })
}

export function useProjectById(projectId: string) {
  const currentTenantId = useTenantStore((state) => state.currentTenant?.id)
  return useQuery({
    queryKey: [ProjectTags.Detail, projectId],
    queryFn: async () => {
      return client.get<Project>(`/tenants/${currentTenantId}/projects/${projectId}`).then((res) => res.data)
    },
  })
}

export function useCreateProjectMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: CreateProjectDTO) => {
      // Create FormData
      const formData = new FormData()
      formData.append('name', data.name)

      data.files.forEach((file) => {
        formData.append('files', file)
      })

      // Mock
      return new Promise<CreateProjectResponse>((resolve) => {
        setTimeout(() => {
          resolve({
            id: Math.random().toString(36).substr(2, 9),
            name: data.name,
            location: 'Angola',
            area: 8888,
            image: '/src/assets/img/project_placeholder.png',
          })
        }, 500)
      })

      // uncomment for real request
      // return client.post<CreateProjectResponse>('/projects', formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //   },
      // }).then(res => res.data)
    },

    // Optimistic update
    onSuccess: (newProject) => {
      queryClient.setQueryData([ProjectTags.List], (old: any) => ({
        pages: old.pages.map((page: Project[], index: number) => (index === 0 ? [newProject, ...page] : page)),
        pageParams: old.pageParams,
      }))

      queryClient.invalidateQueries({ queryKey: [ProjectTags.List] })
    },

    onError: (error) => {
      console.error('Failed to create project:', error)
    },
  })
}
