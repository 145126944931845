import axios, { AxiosError } from 'axios'
import { STORAGE_KEYS } from '../constants/storageKeys'
import { handleTokenRefresh } from './refreshToken'

const api = import.meta.env.VITE_APP_API_URL
const baseURL = `${api}/`

const client = axios.create({ baseURL })

// Request interceptor - adds Authorization header
client.interceptors.request.use((config) => {
  const accessToken =
    localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) || sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN)
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`
  }
  return config
})

// Response interceptor - handles token refresh
client.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const errorResponse = error.response?.data as { message: string; error: string; statusCode: number }
    const token = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) || sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN)
    if (error.response?.status === 401 && errorResponse?.message === 'Token is invalid or expired' && token) {
      return handleTokenRefresh(error, (config) => client(config))
    }
    return Promise.reject(error)
  },
)

export { client }
