import { TokenResponse } from '../api/services/authApi'

export const ACCESS_TOKEN = 'ACCESS_TOKEN'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const IS_REMEMBER_ME = 'IS_REMEMBER_ME'
export const INVITE_TOKEN = 'INVITE_TOKEN'
export const IS_SHOW_CONFETI = 'IS_SHOW_CONFETI'
export const MARKETING_MAILING = 'MARKETING_MAILING'

export const STORAGE_KEYS = {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  IS_REMEMBER_ME,
  MARKETING_MAILING,
  INVITE_TOKEN,
  IS_SHOW_CONFETI,
}

export const trasformStringToBoolean = (value: string | null) => {
  return value === 'true'
}

export const saveTokens = (tokens: TokenResponse, remember: boolean, inviteToken?: string | null) => {
  const storage = remember ? localStorage : sessionStorage
  storage.setItem(STORAGE_KEYS.ACCESS_TOKEN, tokens.access_token)
  storage.setItem(STORAGE_KEYS.REFRESH_TOKEN, tokens.refresh_token)
  localStorage.setItem(STORAGE_KEYS.IS_REMEMBER_ME, String(remember))
  inviteToken && localStorage.setItem(STORAGE_KEYS.INVITE_TOKEN, inviteToken)
}

export const clearTokens = () => {
  localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN)
  localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN)
  sessionStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN)
  sessionStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN)
  localStorage.removeItem(STORAGE_KEYS.IS_REMEMBER_ME)
}
