import { FC } from 'react'
import { Loader } from 'lucide-react'
import { UserTenantRole } from 'src/types/tenant'
import { useGetTenantsByUserId } from 'src/enviroment/api/services/superAdminApi'
import Typography from 'src/components/atoms/Typography'

interface ExpandedRowProps {
  userId: string
  description: string
  purpose: string
  colSpan: number
}

export const TenantExpandedRow: FC<ExpandedRowProps> = ({ userId, colSpan, description, purpose }) => {
  const { data: tenants, isLoading } = useGetTenantsByUserId(userId)

  if (isLoading) {
    return (
      <tr>
        <td colSpan={colSpan}>
          <div className="flex justify-center p-4">
            <Loader className="h-5 w-5 animate-spin text-light-green-600" />
          </div>
        </td>
      </tr>
    )
  }

  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="space-y-4 rounded-b-md bg-gray-900 p-6">
          <div className="flex flex-row space-x-5">
            <div className="space-y-2">
              <Typography variant="base" className="text-gray-400">
                Description:
              </Typography>
              <Typography variant="base" className="text-white">
                {description}
              </Typography>
            </div>

            <div className="space-y-2">
              <Typography variant="base" className="text-gray-400">
                Purpose:
              </Typography>
              <Typography variant="base" className="text-white">
                {purpose}
              </Typography>
            </div>
          </div>

          <div className="space-y-2">
            <Typography variant="base" className="text-gray-400">
              Tenants:
            </Typography>
            <div className="flex flex-wrap gap-3">
              {tenants?.map((tenant: UserTenantRole) => (
                <div key={tenant.id} className="flex items-center rounded bg-gray-800 px-3 py-2">
                  <span className="font-medium text-white">{tenant.tenant.name}</span>
                  <span className="ml-2 text-sm text-gray-400">({tenant.role.toLowerCase()})</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </td>
    </tr>
  )
}
