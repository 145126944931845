import React, { useState } from 'react'
import { Camera } from 'lucide-react'
import * as Popover from '@radix-ui/react-popover'
import { useTenantStore } from 'src/enviroment/store/tenentStore'
import Typography from '../atoms/Typography'
import { enqueueSnackbar } from 'notistack'

enum SUBSCRIPTION_PLAN {
  FREE = 'FREE',
  PREMIUM = 'PREMIUM',
  ENTERPRISE = 'ENTERPRISE',
}

interface CompanyLogoUploadProps {
  currentLogo: string | null
  spaceName: string
  onLogoSelect: (file: File) => void
  onLogoRemove: () => void
}

const CompanyLogoUpload = ({ currentLogo, spaceName, onLogoSelect, onLogoRemove }: CompanyLogoUploadProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const currentPlan = useTenantStore((state) => state.currentTenant.plan)
  let hoverTimeout: NodeJS.Timeout

  const isLogoUpdateAllowed = currentPlan !== SUBSCRIPTION_PLAN.FREE

  const handleMouseEnter = () => {
    clearTimeout(hoverTimeout)
    setIsPopoverOpen(true)
  }

  const handleMouseLeave = () => {
    hoverTimeout = setTimeout(() => {
      setIsPopoverOpen(false)
    }, 100)
  }

  const validateFile = (file: File) => {
    const validTypes = ['image/png', 'image/jpeg', 'image/svg+xml']

    if (!validTypes.includes(file.type)) {
      alert('Please upload a PNG, JPG or SVG file')
      return false
    }

    if (file.size > 2 * 1024 * 1024) {
      alert('Please upload an image smaller than 2MB')
      return false
    }

    return true
  }

  const handleFileSelect = () => {
    if (!isLogoUpdateAllowed) {
      enqueueSnackbar('You need to upgrade to premium to change the logo', { variant: 'error' })
      return
    }

    const input = document.createElement('input')
    input.type = 'file'
    input.accept = 'image/png,image/jpeg,image/svg+xml'
    input.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files?.[0]
      if (!file) return

      const isValid = await validateFile(file)
      if (isValid) {
        onLogoSelect(file)
      }
    }
    input.click()
  }

  const getInitials = (name: string) => {
    return name
      .split(' ')
      .map((word) => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2)
  }

  return (
    <div className="flex items-start">
      <div className="relative">
        <div
          className={`group relative h-20 w-20 overflow-hidden rounded-lg transition-colors ${
            currentLogo ? 'bg-gray-800' : 'bg-gray-800'
          } ${isLogoUpdateAllowed ? 'cursor-pointer' : 'cursor-not-allowed'}`}
          onClick={handleFileSelect}
        >
          {currentLogo ? (
            <img src={currentLogo} alt="Company logo" className="h-full w-full object-contain" />
          ) : (
            <div className="flex h-full w-full items-center justify-center bg-gray-900 text-2xl text-emerald-400">
              {getInitials(spaceName)}
            </div>
          )}
        </div>

        {isLogoUpdateAllowed && (
          <Popover.Root open={isPopoverOpen}>
            <Popover.Trigger asChild>
              <div
                className="absolute -bottom-2 -right-2 cursor-pointer rounded-md border border-emerald-500 bg-gray-800 p-1"
                onClick={handleFileSelect}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Camera size={16} className="text-emerald-400" />
              </div>
            </Popover.Trigger>

            <Popover.Portal>
              <Popover.Content
                className="z-50 rounded-lg bg-gray-700 p-3 shadow-md"
                sideOffset={5}
                side="right"
                align="center"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div className="space-y-2 text-sm text-white">
                  <div>PNG, JPG</div>
                  <div>Min: 200×200px</div>
                  <div>Max: 2MB</div>
                </div>
                <Popover.Arrow className="fill-gray-700" />
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        )}
      </div>

      {!isLogoUpdateAllowed && (
        <div className="ml-2   items-center justify-center rounded-full bg-light-green-400 px-2 py-1">
          <Typography variant="sm-medium">Premium</Typography>
        </div>
      )}
    </div>
  )
}

export default CompanyLogoUpload
