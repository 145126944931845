export enum UserRole {
  VIEWER = 'VIEWER',
  CONTRIBUTOR = 'CONTRIBUTOR',
  ADMIN = 'ADMIN',
}

export enum ONBOARDING_STATUS {
  TELL_US_ABOUT_YOURSELF = 'tell-us-about-yourself',
  DONE = 'done',
  WAIT = 'wait',
}

export interface ITenantMemberAccess {
  id: string
  userId: string
  tenantId: string
  hasGlobalAccess: boolean
  role: UserRole
  createdAt: string
  updatedAt: string
}

export enum SUBSCRIPTION_PLAN {
  FREE = 'FREE',
  PREMIUM = 'PREMIUM',
  ENTERPRISE = 'ENTERPRISE',
}

export interface ITenant {
  logo: null
  id: string
  name: string
  membersCount: number
  logoUrl: string | null
  plan: SUBSCRIPTION_PLAN
  hectares: number
  createdAt: string
  updatedAt: string
  tenantMemberAccess: ITenantMemberAccess
}

export interface IUser {
  role: UserRole
  id: string
  email: string
  firstName: string
  lastName: string
  company: string
  companyWebsite: string
  isSuperadmin: boolean
  companyRole: string
  interest: string
  isLetIn: boolean
  description: string
  purpose: string
  createdAt: string
  tenants: ITenant[]
  onboarding: ONBOARDING_STATUS
  updatedAt: string
  lastLoginAt: string
}

export interface IMember {
  id: string
  email: string
  firstName: string
  lastName: string
  company: string
  description: string
  purpose: string
  isLetIn: boolean
  marketingMailing: boolean
  lastLoginAt: string
  createdAt: string
  updatedAt: string
  tenantMemberAccess: ITenantMemberAccess
}
