import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { useTenantStore } from '../store/tenentStore'
import { ITenant, SUBSCRIPTION_PLAN } from 'src/types/user'
import { plans, roles } from '../constants/constants'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatArea(number: number) {
  const units = ['', 'k', 'M', 'B', 'T', 'Q']
  let unitIndex = 0

  while (number >= 1000 && unitIndex < units.length - 1) {
    number /= 1000
    unitIndex++
  }

  return `${number.toFixed(1)}${units[unitIndex]} ha`
}

export function setDefaultTenant(tenants: ITenant[]) {
  if (tenants && tenants.length > 0) {
    useTenantStore.setState({ currentTenant: tenants[0] })
  }
}

export const getCurrentRoleLabel = (value: string) => {
  return roles.find((role) => role.value === value)?.label || 'Select Role'
}

export const getCurrentPlanLabel = (value: SUBSCRIPTION_PLAN) => {
  return plans.find((plan) => plan.value === value)?.label || 'Select Plan'
}
