import { zodResolver } from '@hookform/resolvers/zod'
import { IconPlus } from '@tabler/icons-react'
import { useCallback, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import { PrimaryButton } from '../atoms/buttons/PrimaryButton'
import { SecondaryButton } from '../atoms/buttons/SecondaryButton'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../atoms/dialog'
import { Input } from '../atoms/inputs/Input'
import { useAddNewProject } from 'src/enviroment/api/services/superAdminApi'
import Typography from '../atoms/Typography'

const defaultValues: AddProjectFormData = {
  name: '',
  location: '',
  mapUrl: '',
  totalArea: 0,
}

const AddProjectSchema = z.object({
  name: z.string().trim().min(3, { message: 'Name must be at least 3 characters' }),
  location: z.string().trim().min(3, { message: 'Country must be at least 3 characters' }),
  mapUrl: z.string().url({ message: 'Invalid URL' }),
  totalArea: z.number({ coerce: true }).positive({ message: 'Area size must be a positive number' }),
})

export type AddProjectFormData = z.infer<typeof AddProjectSchema>

const AddProjectDialog = () => {
  const [opened, setOpened] = useState(false)
  const handleClose = useCallback(() => setOpened(false), [])

  const form = useForm<AddProjectFormData>({
    mode: 'all',
    defaultValues,
    reValidateMode: 'onChange',
    resolver: zodResolver(AddProjectSchema),
  })

  const { mutateAsync: createNewProject } = useAddNewProject()

  const handleSubmit: SubmitHandler<AddProjectFormData> = useCallback(async (data) => {
    await createNewProject(data)
    setOpened(false)
    form.reset()
  }, [])

  return (
    <Dialog open={opened} onOpenChange={setOpened}>
      <DialogTrigger asChild>
        <SecondaryButton className="flex-shrink-0" leftIcon={<IconPlus />}>
          Add Project
        </SecondaryButton>
      </DialogTrigger>
      <DialogContent aria-description="add-project-dialog">
        <DialogHeader>
          <DialogTitle>
            <Typography variant="xl">Add Project</Typography>
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-6">
          <Input
            type="text"
            disabled={form.formState.isSubmitting}
            autoComplete="off"
            label="Name of Project"
            placeholder="Name of Project"
            {...form.register('name')}
          />
          <Input
            type="text"
            disabled={form.formState.isSubmitting}
            autoComplete="off"
            label="Location"
            placeholder="Angola"
            {...form.register('location')}
          />
          <Input
            type="text"
            disabled={form.formState.isSubmitting}
            autoComplete="off"
            label="Map Link"
            placeholder="https://felt.com/map/Deforestation-Risk-G2K46e8kT9CWoinzt7rxWfB?loc=-11.262,17.919,5.5z"
            {...form.register('mapUrl')}
          />
          <Input
            type="number"
            disabled={form.formState.isSubmitting}
            autoComplete="off"
            label="Area Size"
            placeholder="9339.339"
            {...form.register('totalArea')}
          />
        </div>
        <DialogFooter className="sm:justify-center sm:space-x-6">
          <SecondaryButton isLoading={form.formState.isSubmitting} onClick={handleClose} className="w-40">
            Back
          </SecondaryButton>
          <PrimaryButton
            onClick={form.handleSubmit(handleSubmit)}
            isLoading={form.formState.isSubmitting}
            className="w-40"
          >
            Create
          </PrimaryButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AddProjectDialog
