import React from 'react'
import { cn } from 'src/enviroment/lib/utils'
import { Slot } from '@radix-ui/react-slot'
import { IconLoader2 } from '@tabler/icons-react'

export interface PrimaryButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean
  isLoading?: boolean
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  size?: 'sm' | 'md' | 'lg'
  fullWidth?: boolean
}

const sizeClasses = {
  sm: 'h-10 px-4 text-sm', // Small
  md: 'h-12 px-6 text-base', // Medium (default)
  lg: 'h-14 px-8 text-base', // Large
}

const PrimaryButton = React.forwardRef<HTMLButtonElement, PrimaryButtonProps>(
  (
    {
      className,
      asChild = false,
      isLoading = false,
      leftIcon,
      rightIcon,
      size = 'md',
      fullWidth = false,
      children,
      disabled,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    const isDisabled = disabled || isLoading

    return (
      <Comp
        className={cn(
          // Base styles
          'relative inline-flex items-center justify-center rounded-lg font-medium',
          'transition-colors duration-200 active:scale-95',

          // Default state
          'bg-light-green-500 text-gray-50',

          // States
          'hover:bg-light-green-600', // Hover state
          'active:bg-light-green-700', // Pressed state
          'disabled:cursor-not-allowed disabled:bg-light-green-900 disabled:text-light-green-700', // Disabled state

          // Focus
          'focus:outline-none focus-visible:ring-2 focus-visible:ring-light-green-500 focus-visible:ring-offset-2',

          // Sizes
          sizeClasses[size],

          // Full width option
          fullWidth && 'w-full',

          className,
        )}
        ref={ref}
        disabled={isDisabled}
        {...props}
      >
        {isLoading ? (
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <IconLoader2 className="h-5 w-5 animate-spin" />
          </div>
        ) : (
          <div className="flex items-center gap-2">
            {leftIcon}
            <span>{children}</span>
            {rightIcon}
          </div>
        )}
      </Comp>
    )
  },
)

PrimaryButton.displayName = 'PrimaryButton'

export { PrimaryButton }
