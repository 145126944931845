import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import Typography from 'src/components/atoms/Typography'
import { useFeltEmbed } from 'src/enviroment/lib/feltUtils'
import { useGetLinkForExportDownload, useProjectById } from 'src/enviroment/api/services/projectsApi'
import { Loader } from 'lucide-react'
import { Layer } from '@feltmaps/js-sdk'
import LayerList from 'src/components/organisms/LayersList'

const ProjectDetailsPage: React.FC = () => {
  const { id } = useParams() as { id: string }
  const { mutateAsync: getExportLinkAsync } = useGetLinkForExportDownload()
  const [selectedLayer, setSelectedLayer] = useState<Layer | undefined>()
  const [allLayers, setAllLayers] = useState<Layer[]>([])
  const { data, isLoading, isError } = useProjectById(id)

  const { felt, mapRef } = useFeltEmbed(data?.mapId, {
    uiControls: {
      cooperativeGestures: false,
      fullScreenButton: false,
      showLegend: true,
    },
  })

  useEffect(() => {
    if (!felt) return

    const initializeSelection = async () => {
      try {
        const selection = await felt.getSelection()
        const layers = await felt.getLayers()
        setAllLayers(layers.filter((layer): layer is Layer => layer !== null))

        if (selection.length > 0) {
          const layerId = (selection[0].entity as any).layerId
          try {
            const layer = await felt.getLayer(layerId)
            if (layer) setSelectedLayer(layer)
          } catch (error) {
            console.error('Error getting layer:', error)
          }
        }
      } catch (error) {
        console.error('Error getting initial selection:', error)
      }
    }

    initializeSelection()

    const unsubscribe = felt.onSelectionChange({
      handler: async ({ selection }) => {
        if (selection.length === 0) {
          setSelectedLayer(undefined)
          console.log('Nothing is selected')
          return
        }

        const layerId = (selection[0].entity as any).layerId
        try {
          const layer = await felt.getLayer(layerId)
          if (layer) setSelectedLayer(layer)
        } catch (error) {
          console.error('Error getting layer:', error)
        }
      },
    })

    return () => {
      unsubscribe()
    }
  }, [felt])

  const onExport = async () => {
    try {
      if (selectedLayer) {
        await getExportLinkAsync({ projectId: id, layerId: selectedLayer.id })
      }
    } catch (error) {
      console.error('Error exporting layer:', error)
    }
  }

  const onExportLayer = async (layer: Layer) => {
    try {
      await getExportLinkAsync({ projectId: id, layerId: layer.id })
    } catch (error) {
      console.error('Error exporting layer:', error)
    }
  }

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center text-gray-50">
        <Loader />
      </div>
    )
  }

  if (isError) {
    return (
      <div className="flex h-full items-center justify-center">
        <Typography variant="lg" className="text-red-500">
          Error loading project
        </Typography>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>RAMO - Project Details</title>
      </Helmet>

      <div className="flex h-full flex-col">
        {allLayers.length > 0 && <LayerList layers={allLayers} onExport={onExportLayer} />}

        <div className="relative flex-1" ref={mapRef}>
          <div
            style={
              {
                '& > iframe': {
                  position: 'relative',
                  zIndex: 1,
                },
              } as React.CSSProperties
            }
          >
            {!felt && (
              <div className="absolute inset-0 z-0 flex items-center justify-center">
                <div className="flex flex-col items-center gap-3">
                  <Loader className="h-6 w-6 animate-spin text-gray-400" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectDetailsPage
