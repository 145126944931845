import { ComponentProps, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForgotPassword } from 'src/enviroment/api/services/authApi'
import ForgotPasswordTemplate from 'src/templates/auth/forgot-password/ForgotPasswordTemplate'
import { ROUTES } from 'src/types/routes'

export default function ForgotPasswordPage() {
  const navigate = useNavigate()
  const { mutateAsync, isLoading } = useForgotPassword()

  const handleSubmit: ComponentProps<typeof ForgotPasswordTemplate>['onSubmit'] = async ({ email }) => {
    await mutateAsync(email)
    navigate(ROUTES.FORGOT_PASSWORD_SENT, { state: { email } })
  }
  const goBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return <ForgotPasswordTemplate onSubmit={handleSubmit} isLoading={isLoading} goBack={goBack} />
}
