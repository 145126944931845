import { useMutation } from '@tanstack/react-query'
import { client } from '../axios'
import { clearTokens, saveTokens, STORAGE_KEYS } from 'src/enviroment/constants/storageKeys'
import { handleApiError } from '../errorHandler'
import { ITenant, ONBOARDING_STATUS } from 'src/types/user'
import { setDefaultTenant } from 'src/enviroment/lib/utils'

interface SignInCredentials {
  username: string
  password: string
  invitationToken?: string | null
}

interface SignUpCredentials {
  username: string
  password: string
  confirmPassword: string
  invitationToken?: string | null
}

export interface TokenResponse {
  access_token: string
  refresh_token: string
  token_type: string
  expires_in: number
}

export interface SingInResponse extends TokenResponse {
  onboarding: ONBOARDING_STATUS
  tenants: ITenant[]
}

export const authQueryKeys = {
  user: ['user'] as const,
  tokens: ['tokens'] as const,
}

export const authApi = {
  async signIn(credentials: SignInCredentials): Promise<SingInResponse> {
    const { data } = await client.post('/auth/local/login', credentials)
    return data
  },

  async signUp(credentials: SignUpCredentials): Promise<TokenResponse> {
    const { data } = await client.post('/auth/local/signup', credentials)
    return data
  },

  async refreshToken(token: string): Promise<TokenResponse> {
    const { data } = await client.post('/tokens/refresh', { token })
    return data
  },

  async forgotPassword(email: string): Promise<void> {
    await client.post('/password/forgot', { email })
  },

  async resetPassword(params: { token: string; password: string; confirmPassword: string }): Promise<void> {
    await client.post('/password/reset', params)
  },
}

export const useSignIn = () => {
  return useMutation({
    mutationFn: async ({
      username,
      password,
      isRemember,
      invitationToken,
    }: SignInCredentials & { isRemember: boolean }) => {
      const response = await authApi.signIn({ username, password, invitationToken })
      saveTokens(response, isRemember)
      setDefaultTenant(response.tenants)
      return response
    },
    onError: handleApiError,
  })
}

export const useSignUp = () => {
  return useMutation({
    mutationFn: async ({
      username,
      password,
      confirmPassword,
      isRemember,
      invitationToken,
    }: SignUpCredentials & { isRemember: boolean; invitationToken?: string | null }) => {
      const response = await authApi.signUp({ username, password, confirmPassword, invitationToken })
      saveTokens(response, isRemember, invitationToken)
      return response
    },
    onError: handleApiError,
  })
}

export const useLogout = () => {
  return useMutation({
    mutationFn: async () => {
      clearTokens()
    },
  })
}

export const useRefreshToken = () => {
  return useMutation({
    mutationFn: (token: string) => authApi.refreshToken(token),
    onSuccess: (data) => {
      localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, data.access_token)
      localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, data.refresh_token)
    },
    onError: handleApiError,
  })
}

export const useForgotPassword = () => {
  return useMutation({
    mutationFn: (email: string) => authApi.forgotPassword(email),
    onError: handleApiError,
  })
}

export const useResetPassword = () => {
  return useMutation({
    mutationFn: (params: { token: string; password: string; confirmPassword: string }) => authApi.resetPassword(params),
    onError: handleApiError,
  })
}
