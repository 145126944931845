import React from 'react'
import { Helmet } from 'react-helmet-async'
import AuthWrapper from 'src/enviroment/layouts/auth-wrapper'
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { z } from 'zod'
import { signUpSchemaPersonalInfo } from 'src/enviroment/validation/schema'
import Typography from 'src/components/atoms/Typography'
import { Input } from 'src/components/atoms/inputs/Input'
import { PrimaryButton } from 'src/components/atoms/buttons/PrimaryButton'
import DropDown from 'src/components/atoms/DropDown'
import { userPurposes, userTypes } from 'src/enviroment/constants/constants'

type SignUpPersonalInfoFormData = z.infer<typeof signUpSchemaPersonalInfo>

const defaultValues = {
  firstName: '',
  lastName: '',
  companyName: '',
  description: '',
  purpose: '',
} satisfies SignUpPersonalInfoFormData

export interface TellUsAboutYourselfProps {
  onSubmit: SubmitHandler<SignUpPersonalInfoFormData>
  isLoading?: boolean
}

const TellUsAboutYourself: React.FC<TellUsAboutYourselfProps> = ({ onSubmit, isLoading = false }) => {
  const methods = useForm<SignUpPersonalInfoFormData>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(signUpSchemaPersonalInfo),
  })

  const {
    handleSubmit,
    formState: { errors, isValid },
  } = methods

  return (
    <FormProvider {...methods}>
      <Helmet>
        <title>RAMO - Tell Us About Yourself</title>
      </Helmet>
      <AuthWrapper>
        <div className="space-y-8">
          <Typography variant="4xl" className="w-max text-center">
            Tell us about yourself
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <Controller
              name="firstName"
              control={methods.control}
              render={({ field: { onChange, ...field }, formState }) => (
                <Input
                  {...field}
                  onChange={onChange}
                  label="First Name"
                  autoFocus
                  type="text"
                  errorMessage={formState.errors.firstName?.message}
                />
              )}
            />
            <Controller
              name="lastName"
              control={methods.control}
              render={({ field: { onChange, ...field }, formState }) => (
                <Input
                  {...field}
                  onChange={onChange}
                  label="Last Name"
                  type="text"
                  errorMessage={formState.errors.lastName?.message}
                />
              )}
            />
            <Controller
              name="companyName"
              control={methods.control}
              render={({ field: { onChange, ...field }, formState }) => (
                <Input
                  {...field}
                  onChange={onChange}
                  label="Company"
                  type="text"
                  errorMessage={formState.errors.companyName?.message}
                />
              )}
            />
            <Controller
              name="description"
              control={methods.control}
              render={({ field }) => (
                <DropDown
                  label="What best describes you?"
                  options={userTypes}
                  value={field.value}
                  errorMessage={errors.description?.message}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              name="purpose"
              control={methods.control}
              render={({ field }) => (
                <DropDown
                  label="What best describes you?"
                  options={userPurposes}
                  value={field.value}
                  errorMessage={errors.purpose?.message}
                  onChange={field.onChange}
                />
              )}
            />
            <div className="space-y-4">
              <PrimaryButton type="submit" isLoading={isLoading} disabled={!isValid} fullWidth>
                Next
              </PrimaryButton>
            </div>
          </form>
        </div>
      </AuthWrapper>
    </FormProvider>
  )
}

export default TellUsAboutYourself
