import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { client } from '../axios'
import { userQueryKeys } from './userApi'
import { ITenant, IUser, SUBSCRIPTION_PLAN } from 'src/types/user'
import { Project } from 'src/types/projects'
import { GlobalTenantsResponse, Tenant, UserTenantRoles } from 'src/types/tenant'
import { handleApiError } from '../errorHandler'
import { AddProjectFormData } from 'src/components/organisms/AddProjectDialog'
import { enqueueSnackbar } from 'notistack'
import { tenantsQueryKeys } from './tenantsApi'
import { useTenantStore } from 'src/enviroment/store/tenentStore'

export const queryTool = (query: string | undefined) => {
  return query !== '' ? query : undefined
}

export enum SuperAdminQueryKeys {
  UsersList = 'UsersList',
  GlobalProjectList = 'GlobalProjectList',
  GlobalTenantsList = 'GlobalTenantsList',
  TenantsProject = 'TenantsProject',
  CurrentTenantsOfProject = 'CurrentTenantsOfProject',
}

interface UsersResponse {
  users: IUser[]
  total: number
  nextCursor: string
}
interface ProjectsResponse {
  projects: Project[]
  total: number
  nextCursor: string
}

interface TenantsResponse {
  tenants: ITenant[]
  total: number
  nextCursor: string
}

interface QueryParams {
  cursor?: string
  take?: number
  query?: string
  isApproved?: boolean
}

const DEFAULT_TAKE = 10

export function useUsersQuery(params: QueryParams = {}) {
  const queryClient = useQueryClient()
  const { take = DEFAULT_TAKE, query, isApproved } = params

  return useInfiniteQuery({
    queryKey: [SuperAdminQueryKeys.UsersList, query, isApproved],
    queryFn: async ({ pageParam }) => {
      const response = await client.get<UsersResponse>('/users', {
        params: {
          cursor: pageParam,
          take,
          query: queryTool(query),
          isApproved,
        },
      })
      return response.data
    },
    enabled: Boolean(queryClient.getQueryData(userQueryKeys.user)),
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  })
}

export function useDeleteUser() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id: string) => {
      await client.delete(`/users/${id}`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([SuperAdminQueryKeys.UsersList])
    },
  })
}

export function useApproveUser() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id: string) => {
      await client.patch(`/users/${id}/approve`)
    },
    onSuccess: () => {
      enqueueSnackbar('User approved successfully', { variant: 'success' })
      queryClient.invalidateQueries([SuperAdminQueryKeys.UsersList])
    },
  })
}

export function useGlobalProjectQuery(params: QueryParams = {}) {
  const queryClient = useQueryClient()
  const { take = DEFAULT_TAKE, query } = params

  return useInfiniteQuery({
    queryKey: [SuperAdminQueryKeys.GlobalProjectList, query],
    queryFn: async ({ pageParam }) => {
      const response = await client.get<ProjectsResponse>('/projects', {
        params: {
          cursor: pageParam,
          take,
          query: queryTool(query),
        },
      })
      return response.data
    },
    enabled: Boolean(queryClient.getQueryData(userQueryKeys.user)),
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  })
}

export function useGlobalTenantsQuery(params: QueryParams = {}) {
  const queryClient = useQueryClient()
  const { take = DEFAULT_TAKE, query } = params

  return useInfiniteQuery({
    queryKey: [SuperAdminQueryKeys.GlobalTenantsList, query],
    queryFn: async ({ pageParam }) => {
      const response = await client.get<TenantsResponse>('/tenants', {
        params: {
          cursor: pageParam,
          take,
          query: queryTool(query),
        },
      })
      return response.data
    },
    enabled: Boolean(queryClient.getQueryData(userQueryKeys.user)),
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  })
}

export function useGetTenantsByUserId(userId: string) {
  return useQuery({
    queryKey: ['tenants', userId],
    queryFn: async () => {
      const response = await client.get<UserTenantRoles>(`/users/${userId}:tenant-access`)
      return response.data
    },
  })
}

export function useUpdateTenant() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: { id: string; plan: SUBSCRIPTION_PLAN; name?: string }) => {
      await client.patch(`/tenants/${data.id}`, { plan: data.plan, name: data.name })
    },
    onSuccess: () => {
      queryClient.invalidateQueries([SuperAdminQueryKeys.GlobalTenantsList])
      queryClient.invalidateQueries([tenantsQueryKeys.tenants])
    },
  })
}

export function useAddNewProject() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: AddProjectFormData) => {
      await client.post('/projects', data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([SuperAdminQueryKeys.GlobalProjectList])
    },
    onError: handleApiError,
  })
}

export interface UpdateProjectData {
  id: string
  name: string
  location: string
  totalArea: number
}
export function useUpdateGlobalProjectMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: UpdateProjectData) => {
      return client.patch(`/projects/${data.id}`, data)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [SuperAdminQueryKeys.GlobalProjectList] })
    },
    onError: handleApiError,
  })
}

export function useGetTenantsByProject(params: QueryParams = {}, enabled: boolean) {
  const { take = DEFAULT_TAKE, query } = params

  return useInfiniteQuery({
    queryKey: [SuperAdminQueryKeys.TenantsProject, query],
    queryFn: async ({ pageParam }) => {
      const response = await client.get<GlobalTenantsResponse>('/tenants', {
        params: {
          cursor: pageParam,
          take,
          query: queryTool(query),
        },
      })
      return response.data
    },
    enabled: enabled,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    onError: handleApiError,
  })
}

export function useDeleteGlobalProjectMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (projectId: string) => {
      return client.delete(`/projects/${projectId}`)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [SuperAdminQueryKeys.GlobalProjectList] })
    },
    onError: handleApiError,
  })
}

export function useGetCurrentTenantsOfProject(projectId: string) {
  return useQuery({
    queryKey: [SuperAdminQueryKeys.CurrentTenantsOfProject, projectId],
    queryFn: async () => {
      const response = await client.get<Tenant[]>(`/projects/${projectId}:tenants`)
      return response.data
    },
    onError: handleApiError,
  })
}

export function useUpdateProjectTenants(projectId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (tenantIds: string[]) => {
      await client.put(`/projects/${projectId}:tenants`, { tenantIds })
    },
    onSuccess: () => {
      queryClient.invalidateQueries([SuperAdminQueryKeys.CurrentTenantsOfProject, projectId])
    },
    onError: handleApiError,
  })
}
