import { ComponentProps, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSignIn } from 'src/enviroment/api/services/authApi'
import { useAcceptInvitation } from 'src/enviroment/api/services/userApi'
import { useToast } from 'src/enviroment/hooks/use-toast'
import useAuth from 'src/enviroment/hooks/useAuth'
import SignInTemplate from 'src/templates/auth/sign-in/SignInTemplate'
import { ROUTES } from 'src/types/routes'

type OnboardingStatus = 'tell-us-about-yourself' | 'done' | 'wait'

const ONBOARDING_ROUTES: Record<OnboardingStatus, string> = {
  'tell-us-about-yourself': ROUTES.PERSONAL_INFO,
  done: ROUTES.PROJECTS,
  wait: ROUTES.REQUEST_SENT,
}

export default function SignInPage() {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth()
  const { toast } = useToast()
  const [isInitializing, setIsInitializing] = useState<boolean>(true)

  const searchParams = new URLSearchParams(window.location.search)
  const invitationToken = searchParams.get('token')
  const invitedEmail = searchParams.get('email')

  const { mutateAsync: signIn, isLoading: isSigningIn } = useSignIn()
  const { mutateAsync: acceptInvitation } = useAcceptInvitation()

  const handleSubmit: ComponentProps<typeof SignInTemplate>['onSubmit'] = useCallback(
    async ({ email, password, isRemember }) => {
      try {
        const { onboarding } = await signIn({
          username: email,
          password,
          isRemember,
          invitationToken,
        })

        const redirectRoute = ONBOARDING_ROUTES[onboarding]
        if (redirectRoute) {
          navigate(redirectRoute)
        } else {
          toast({
            title: 'Error',
            description: 'Invalid onboarding status received',
            variant: 'destructive',
          })
        }
      } catch (error) {
        toast({
          title: 'Sign in failed',
          description: error instanceof Error ? error.message : 'An unexpected error occurred',
          variant: 'destructive',
        })
      }
    },
    [signIn, navigate, invitationToken, toast],
  )

  const handleForgotPassword = useCallback(() => {
    navigate(ROUTES.FORGOT_PASSWORD)
  }, [navigate])

  useEffect(() => {
    const handleInitialCheck = async () => {
      if (!isAuthenticated) {
        setIsInitializing(false)
        return
      }

      if (invitationToken) {
        try {
          alert('accepting invitation')
          await acceptInvitation(invitationToken)
          navigate(ROUTES.PROJECTS)
        } catch (error) {
          setIsInitializing(false)
          return
        }
      } else {
        navigate(ROUTES.PROJECTS)
      }
    }

    handleInitialCheck()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptInvitation, invitationToken])

  if (isInitializing) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900" />
      </div>
    )
  }

  return (
    <SignInTemplate
      onSubmit={handleSubmit}
      isLoading={isSigningIn}
      onForgotPassword={handleForgotPassword}
      defaultEmail={invitedEmail}
    />
  )
}
