import React from 'react'
import Typography from '../atoms/Typography'

interface DashboardHeaderProps {
  title: string
  rightSide?: React.ReactNode
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ title, rightSide }) => {
  return (
    <div className="flex w-full items-center justify-between border-b border-b-gray-600 px-8 py-5 text-gray-50">
      <Typography variant="xl">{title}</Typography>
      {rightSide && <div className="ml-4">{rightSide}</div>}
    </div>
  )
}

export default DashboardHeader
