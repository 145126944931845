import { IconTrash } from '@tabler/icons-react'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTrigger,
} from '../atoms/alert-dialog'
import { FC, useCallback, useState } from 'react'
import { Project } from 'src/types/projects'
import { Input } from '../atoms/inputs/Input'
import { useDeleteGlobalProjectMutation } from 'src/enviroment/api/services/superAdminApi'

interface Props {
  project: Project
  requireConfirmation?: boolean
}

const DeleteProjectDialog: FC<Props> = ({ project, requireConfirmation = false }) => {
  const [opened, setOpened] = useState(false)
  const [projectName, setProjectName] = useState('')
  const handleClose = useCallback(() => {
    setOpened(false)
    setProjectName('')
  }, [])

  const { mutateAsync: deleteProjectAsync, isLoading } = useDeleteGlobalProjectMutation()

  const handleDelete = useCallback(async () => {
    try {
      await deleteProjectAsync(project.id)
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }, [deleteProjectAsync, handleClose, project.id])

  const isDeleteDisabled = (requireConfirmation && projectName !== project.name) || isLoading

  return (
    <AlertDialog open={opened} onOpenChange={setOpened}>
      <AlertDialogTrigger className="flex h-11 w-11 items-center justify-center rounded-[8px] text-red-600 transition-all hover:cursor-pointer hover:bg-red-600 hover:text-white active:scale-95">
        <IconTrash size={24} />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <div className="flex flex-col items-center gap-4">
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#FFADAD]">
            <IconTrash className="h-6 w-6 text-red-600" />
          </div>
          <div className="flex flex-col justify-center gap-6">
            <span className="text-center text-xl text-green-50">Delete Project</span>
            {requireConfirmation ? (
              <>
                <span className="text-center text-gray-50">
                  Please type <span className="font-semibold">{project.name}</span> to confirm deletion.
                </span>
                <Input
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                  placeholder="Enter project name"
                  autoComplete="off"
                />
              </>
            ) : (
              <span className="text-center text-gray-50">
                You&apos;re going to delete {project.name} project. Are you sure?
              </span>
            )}
          </div>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isLoading} onClick={handleClose} className="w-40">
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction disabled={isDeleteDisabled} onClick={handleDelete} className="w-40">
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default DeleteProjectDialog
