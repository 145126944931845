import React from 'react'
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogDescription,
  AlertDialogTitle,
} from '@radix-ui/react-alert-dialog'
import ModalContainer from '../organisms/ModalContainer'
import { IconLogout } from '@tabler/icons-react'

interface LogoutDialogProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

const LogoutDialog: React.FC<LogoutDialogProps> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <ModalContainer isOpen={isOpen} onClose={onClose}>
      {/* Header */}
      <div className="flex flex-col space-y-2 text-center sm:text-left">
        <div className="flex flex-col items-center gap-2">
          <div className="mb-2 items-center rounded-full bg-[#FFADAD] p-2">
            <IconLogout className="text-[#D71B3D]" />
          </div>
          <AlertDialogTitle className="font-nexa text-xl text-light-green-50">Already leaving?</AlertDialogTitle>
        </div>
        <AlertDialogDescription className="pt-3 text-center font-inter text-base text-gray-50">
          We look forward to see you again
        </AlertDialogDescription>
      </div>

      {/* Footer */}
      <div className="flex flex-col-reverse gap-4 sm:flex-row sm:justify-center">
        <AlertDialogCancel className="mt-2 inline-flex h-[48px] w-[160px] items-center justify-center rounded-md border border-light-green-500 bg-transparent px-4 py-2 text-sm font-semibold text-light-green-500 ring-offset-background transition-colors hover:bg-gray-800 hover:text-light-green-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 sm:mt-0">
          Cancel
        </AlertDialogCancel>
        <AlertDialogAction
          className="inline-flex h-[48px] w-[160px] items-center justify-center rounded-md border-0 bg-red-500 px-4 py-2 text-sm font-semibold text-white transition-colors hover:bg-red-600 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
          onClick={onConfirm}
        >
          Log out
        </AlertDialogAction>
      </div>
    </ModalContainer>
  )
}

export default LogoutDialog
