import React from 'react'
import { IconExclamationCircle } from '@tabler/icons-react'
import { cn } from 'src/enviroment/lib/utils'
import Typography from './Typography'

interface ErrorMessageProps {
  message?: string
  className?: string
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, className }) => {
  if (!message) return null

  return (
    <div className={cn('flex items-center gap-1 text-[#D71B3D]', className)}>
      <IconExclamationCircle className="h-4 w-4" />
      <Typography variant="sm" className="text-[#D71B3D]">
        {message}
      </Typography>
    </div>
  )
}

export { ErrorMessage }
