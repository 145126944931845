import { z } from 'zod'
import { companyName, email, firstName, description, purpose, lastName, password } from './fields'

export const signInSchema = z.object({
  email,
  password,
  isRemember: z.boolean(),
})

export const signUpSchema = z
  .object({
    email,
    password,
    confirmPassword: password,
    isRemember: z.boolean(),
    marketingMailing: z.boolean(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  })

export const signUpSchemaPersonalInfo = z.object({
  firstName,
  lastName,
  companyName,
  description,
  purpose,
})

export const forgotPasswordSchema = z.object({
  email,
})

export const createPasswordSchema = z
  .object({
    password,
    confirmPassword: password,
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  })

export const supportFormSchema = z.object({
  subject: z.string().min(1, 'Subject is required'),
  description: z.string().min(10, 'Description must be at least 10 characters'),
  file: z.custom<File>().optional(),
})
