import { AxiosError } from 'axios'
import { STORAGE_KEYS, clearTokens } from '../constants/storageKeys'
import { authApi } from './services/authApi'
import { ROUTES } from 'src/types/routes'

interface RetryConfig {
  headers: Record<string, string>
  _retry?: number
}

const MAX_RETRY_ATTEMPTS = 2

export async function handleTokenRefresh(error: AxiosError, retryRequest: (config: RetryConfig) => Promise<any>) {
  const originalRequest = error.config as RetryConfig

  if (originalRequest._retry && originalRequest._retry >= MAX_RETRY_ATTEMPTS) {
    clearTokens()
    window.location.href = ROUTES.SIGN_IN
    return Promise.reject(new Error('Max retry attempts reached'))
  }

  try {
    const refreshToken =
      localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN) || sessionStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN)

    if (!refreshToken) {
      clearTokens()
      window.location.href = ROUTES.SIGN_IN
      return Promise.reject(new Error('No refresh token available'))
    }

    const response = await authApi.refreshToken(refreshToken)
    const storage = localStorage.getItem(STORAGE_KEYS.IS_REMEMBER_ME) === 'true' ? localStorage : sessionStorage
    storage.setItem(STORAGE_KEYS.ACCESS_TOKEN, response.access_token)
    storage.setItem(STORAGE_KEYS.REFRESH_TOKEN, response.refresh_token)

    if (!originalRequest) {
      return Promise.reject(new Error('No original request config'))
    }

    return retryRequest({
      ...originalRequest,
      headers: {
        ...originalRequest.headers,
        Authorization: `Bearer ${response.access_token}`,
      },
      _retry: (originalRequest._retry || 0) + 1,
    })
  } catch (refreshError) {
    window.location.href = ROUTES.LOGOUT
    return Promise.reject(refreshError)
  }
}
