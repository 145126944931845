import { QueryClient } from '@tanstack/react-query'

export const createQueryClient = () => {
  return new QueryClient({
    // defaultOptions: {
    //   queries: {
    //     retry: 1,
    //     refetchOnWindowFocus: false,
    //     staleTime: 5 * 60 * 1000, // 5 minutes
    //   },
    //   mutations: {
    //     retry: 1,
    //   },
    // },
  })
}
