import { FC } from 'react'
import { Tabs, TabsList, TabsTrigger, TabsContent } from 'src/components/molecules/Tabs'
import DashboardHeader from 'src/components/molecules/DashboardHeader'
import { ITenant, IUser } from 'src/types/user'
import { useToast } from 'src/enviroment/hooks/use-toast'
import { UserTable } from './users-pending-table'
import { IconSearch } from '@tabler/icons-react'
import { Input } from 'src/components/atoms/inputs/Input'
import { ProjectTable } from './project-table'
import { Project } from 'src/types/projects'
import AddProjectDialog from 'src/components/organisms/AddProjectDialog'
import Typography from 'src/components/atoms/Typography'
import FilterButton from 'src/components/molecules/FilterButton'
import DataTable from 'src/templates/members/data-table'
import { useColumns } from 'src/templates/dashboard/super-admin/columsTenants'

interface SuperAdminTemplateProps {
  isLoading: boolean
  error: unknown
  projects?: Project[]
  tenants?: ITenant[]
  users?: IUser[]
  hasNextPageUsers: boolean | undefined
  isFetchingNextPageUsers: boolean
  searchTermUsers: string
  searchTermProjects: string
  currentFilter: 'approved' | 'not_approved' | null
  isFethingNextPageGlobalTenants: boolean
  isHasNextPageGlobalTenants: boolean | undefined
  isFetchingNextPageTenants: boolean | undefined
  isLoadingGlobalTenant: boolean
  onFilterChange: (filter: 'approved' | 'not_approved' | null) => void
  fetchNextPageUsers: () => void
  onSearchUsers?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSearchProjects?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onApproveUser?: (userId: string) => void
  fetchNextPageTenants: () => void
}

const SuperAdminTemplate: FC<SuperAdminTemplateProps> = ({
  isLoading,
  error,
  users = [],
  tenants = [],
  projects = [],
  isFetchingNextPageUsers,
  currentFilter,
  searchTermUsers,
  searchTermProjects,
  hasNextPageUsers,
  isFetchingNextPageTenants,
  isHasNextPageGlobalTenants,
  isFethingNextPageGlobalTenants,
  onFilterChange,
  fetchNextPageUsers,
  fetchNextPageTenants,
  onSearchUsers,
  onSearchProjects,
  onApproveUser,
}) => {
  const { toast } = useToast()
  const columns = useColumns()

  const handleApprove = async (userId: string) => {
    try {
      if (onApproveUser) {
        onApproveUser(userId)
        toast({
          title: 'Success',
          description: 'User has been approved',
        })
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to approve user',
        variant: 'destructive',
      })
    }
  }

  return (
    <div className="flex h-full flex-col">
      <DashboardHeader title="Platform Admin" />

      <div className="flex-1 p-6">
        <Tabs defaultValue="pending-users" className="w-full">
          <TabsList className="h-15 ">
            <TabsTrigger className="h-10" value="pending-users">
              Users
            </TabsTrigger>
            <TabsTrigger className="h-10" value="projects">
              Projects
            </TabsTrigger>
            <TabsTrigger className="h-10" value="workspaces">
              Workspaces
            </TabsTrigger>
          </TabsList>

          <TabsContent value="pending-users" className="mt-6 ">
            <div className={`my-6 flex flex-col gap-8 rounded-[8px] bg-gray-800 p-8`}>
              <div className="flex items-center justify-between">
                <Typography variant="xl" className="w-2/4 text-light-green-50">
                  Platform Users
                </Typography>
                <div className="flex w-full items-center gap-6">
                  <Input
                    containerClassName="w-[70%]"
                    icon={<IconSearch />}
                    placeholder="Search by email, first name, last name, company..."
                    onChange={onSearchUsers}
                    value={searchTermUsers}
                  />
                  <div className="w-[30%]">
                    <FilterButton onFilterChange={onFilterChange} initialFilter={currentFilter} />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-6">
                <UserTable
                  users={users}
                  isLoading={isLoading}
                  error={error}
                  onApprove={handleApprove}
                  fetchNextPageUsers={fetchNextPageUsers}
                  hasNextPageUsers={hasNextPageUsers}
                  isFetchingNextPageUsers={isFetchingNextPageUsers}
                />
              </div>
            </div>
          </TabsContent>

          <TabsContent value="projects">
            <div className={`my-6 flex flex-col gap-8 rounded-[8px] bg-gray-800 p-8`}>
              <div className="flex items-center justify-between gap-8">
                <Typography variant="xl" className="shrink-0 text-light-green-50">
                  Platform Projects
                </Typography>
                <div className="ml-auto flex items-center gap-6">
                  <Input
                    containerClassName="w-[300px]"
                    icon={<IconSearch />}
                    placeholder="Search by name, location"
                    onChange={onSearchProjects}
                    value={searchTermProjects}
                  />
                  <AddProjectDialog />
                </div>
              </div>
              <ProjectTable projects={projects} isLoading={isLoading} />
            </div>
          </TabsContent>
          <TabsContent value="workspaces">
            <div className={`my-6 flex flex-col gap-8 rounded-[8px] bg-gray-800 p-8`}>
              <div className="flex items-center justify-between gap-8">
                <Typography variant="xl" className="shrink-0 text-light-green-50">
                  Platform Workspaces
                </Typography>
              </div>
              <DataTable
                columns={columns}
                data={tenants}
                onLoadMore={fetchNextPageTenants}
                isLoading={isFetchingNextPageTenants}
                hasNextPage={isHasNextPageGlobalTenants}
                isFetchingNextPage={isFethingNextPageGlobalTenants}
              />
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  )
}

export default SuperAdminTemplate
