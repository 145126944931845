import { Helmet } from 'react-helmet-async'
import { useState } from 'react'
import { IconPencil } from '@tabler/icons-react'
import {
  IChangePasswordPayload,
  useChangePassword,
  useUpdateUser,
  useUserInformation,
} from 'src/enviroment/api/services/userApi'
import Typography from 'src/components/atoms/Typography'
import Loader from 'src/components/atoms/Loader'
import DashboardHeader from 'src/components/molecules/DashboardHeader'
import { formatDate } from 'src/enviroment/lib/formatters/format-date'
import EditProfileForm from 'src/components/organisms/EditProfileForm'
import { EditButton } from 'src/components/atoms/buttons/EditButton'
import { SecondaryButton } from 'src/components/atoms/buttons/SecondaryButton'
import { IUser } from 'src/types/user'
import ChangePasswordForm from 'src/components/organisms/ChangePasswordForm'

const MyProfilePage = () => {
  const { mutateAsync: mutateAsyncUser, isLoading: isUpdatingUser } = useUpdateUser()
  const { mutateAsync: changePassword, isLoading: isChangingPasswordLoading } = useChangePassword()
  const { data: user, isLoading, error } = useUserInformation()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false)

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loader />
      </div>
    )
  }

  if (error || !user) {
    return (
      <div className="flex h-full items-center justify-center text-red-500">
        <Typography variant="lg">Error loading profile</Typography>
      </div>
    )
  }

  const handleProfileEdit = async (data: Pick<IUser, 'company' | 'firstName' | 'lastName'>) => {
    await mutateAsyncUser(data)
    setIsEditing(false)
  }

  const handlePasswordChange = async (data: IChangePasswordPayload) => {
    await changePassword(data)
    setIsChangingPassword(false)
  }

  return (
    <>
      <Helmet>
        <title>RAMO - My Profile</title>
      </Helmet>

      <DashboardHeader title="My Profile" />

      <div className="flex-1 space-y-8 p-6">
        {/* Personal Information Section */}
        <div className="rounded-lg bg-gray-800 p-8">
          <div className="mb-8 flex justify-between">
            <Typography variant="xl" className="text-light-green-50">
              Personal Information
            </Typography>
            <EditButton onClick={() => setIsEditing(!isEditing)}>
              <IconPencil />
            </EditButton>
          </div>

          {isEditing ? (
            <EditProfileForm
              initialData={{
                firstName: user.firstName,
                lastName: user.lastName,
                company: user.company,
              }}
              isLoading={isUpdatingUser}
              onSubmit={handleProfileEdit}
              onCancel={() => setIsEditing(false)}
            />
          ) : (
            <div className="grid grid-cols-2 gap-8">
              <div>
                <Typography variant="sm-medium" className="text-gray-100">
                  Name
                </Typography>
                <Typography variant="base" className="text-light-green-50">
                  {user.firstName} {user.lastName}
                </Typography>
              </div>

              <div>
                <Typography variant="sm-medium" className="text-gray-100">
                  Email
                </Typography>
                <Typography variant="base" className="text-light-green-50">
                  {user.email}
                </Typography>
              </div>

              <div>
                <Typography variant="sm-medium" className="text-gray-100">
                  Member Since
                </Typography>
                <Typography variant="base" className="text-light-green-50">
                  {formatDate(user.createdAt)}
                </Typography>
              </div>

              <div>
                <Typography variant="sm-medium" className="text-gray-100">
                  Last Updated
                </Typography>
                <Typography variant="base" className="text-light-green-50">
                  {formatDate(user.updatedAt)}
                </Typography>
              </div>
            </div>
          )}
        </div>

        {/* Password Section */}
        <div className="rounded-lg bg-gray-800 p-8">
          {isChangingPassword ? (
            <ChangePasswordForm
              isLoading={isChangingPasswordLoading}
              onSubmit={handlePasswordChange}
              onCancel={() => setIsChangingPassword(false)}
            />
          ) : (
            <>
              <div className="mb-8 flex justify-between">
                <Typography variant="xl" className="text-light-green-50">
                  Password
                </Typography>
                <SecondaryButton onClick={() => setIsChangingPassword(true)}>Change Password</SecondaryButton>
              </div>

              <div className="space-y-2">
                <Typography variant="sm-medium" className="text-gray-100">
                  Current Password
                </Typography>
                <div className="flex items-center space-x-2">
                  <Typography variant="base" className="text-light-green-50">
                    ••••••••••
                  </Typography>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default MyProfilePage
