// components/organisms/ProjectGrid.tsx
import React from 'react'
import ProjectCard from '../molecules/ProjectCard'
import { Project } from 'src/types/projects'

interface ProjectGridProps {
  projects: Project[]
  onOpenProject?: (project: Project) => void
}

const ProjectGrid: React.FC<ProjectGridProps> = ({ projects, onOpenProject }) => {
  return (
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {projects.map((project) => (
        <ProjectCard key={project.id} project={project} onOpen={onOpenProject} />
      ))}
    </div>
  )
}

export default ProjectGrid
