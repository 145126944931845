import React, { useCallback } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './select'
import { cn } from 'src/enviroment/lib/utils'
import { roles } from 'src/enviroment/constants/constants'
import { UserRole } from 'src/types/user'

type Role = (typeof roles)[number]['value']

interface RoleSelectProps {
  value: UserRole
  onRoleChange: (newRole: Role) => void
  className?: string
}

const RoleSelect = ({ value, onRoleChange, className }: RoleSelectProps) => {
  const getCurrentRoleLabel = useCallback(() => {
    return roles.find((role) => role.value === value)?.label || 'Select Role'
  }, [value])
  return (
    <Select value={value} onValueChange={(newValue: Role) => onRoleChange(newValue)}>
      <SelectTrigger className={cn('w-full font-inter text-base', className)}>
        <SelectValue>{getCurrentRoleLabel()}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        {roles.map((role) => (
          <SelectItem key={role.value} value={role.value} className="cursor-pointer font-inter text-base">
            {role.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default RoleSelect
