import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { ITenant } from 'src/types/user'

interface TenantState {
  currentTenant: ITenant
  setCurrentTenant: (tenant: ITenant) => void
  clear: () => void
}

export const useTenantStore = create<TenantState>()(
  persist(
    (set) => ({
      currentTenant: {} as ITenant,
      setCurrentTenant: (tenant) => set({ currentTenant: tenant }),
      clear: () => set({ currentTenant: {} as ITenant }),
    }),
    {
      name: 'tenant-store',
    },
  ),
)
