import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { client } from '../axios'
import { handleApiError } from '../errorHandler'
import { IUser, ONBOARDING_STATUS } from 'src/types/user'
import { tenantsQueryKeys } from './tenantsApi'
import { STORAGE_KEYS } from 'src/enviroment/constants/storageKeys'
import { useTenantStore } from 'src/enviroment/store/tenentStore'

export const userQueryKeys = {
  user: ['user'] as const,
}

export interface ICreateUserPayload {
  body: {
    firstName: string
    lastName: string
    marketingMailing: boolean
    description: string
    purpose: string
    company: string
  }
}
export interface IChangePasswordPayload {
  oldPassword: string
  newPassword: string
  confirmNewPassword: string
}

//helper function
export const addToUserOnboardingField = (user: IUser) => {
  const { isLetIn, company } = user
  let onboarding: ONBOARDING_STATUS = ONBOARDING_STATUS.TELL_US_ABOUT_YOURSELF

  if (company && isLetIn) {
    onboarding = ONBOARDING_STATUS.DONE
  } else if (company && !isLetIn) {
    onboarding = ONBOARDING_STATUS.WAIT
  } else if (!company) {
    onboarding = ONBOARDING_STATUS.TELL_US_ABOUT_YOURSELF
  }
  return {
    ...user,
    onboarding,
  }
}

export const userApi = {
  async createUser(user: ICreateUserPayload): Promise<IUser> {
    const { body } = user
    const { data } = await client.post('/users', body)
    const res = addToUserOnboardingField(data)
    return res
  },

  async updateUser(user: Pick<IUser, 'company' | 'firstName' | 'lastName'>): Promise<IUser> {
    const { data } = await client.patch('/users/myself', user)
    const res = addToUserOnboardingField(data)
    return res
  },

  async getUser(): Promise<IUser> {
    const { data } = await client.get('/users/myself')
    const res = addToUserOnboardingField(data)
    const currentTenant = useTenantStore.getState().currentTenant.id

    if (!currentTenant) {
      useTenantStore.setState({ currentTenant: res.tenants[0] })
    }
    return res
  },

  async changePassword(data: IChangePasswordPayload) {
    await client.post('/password/change', data)
  },
}

export const useCreateUser = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (payload: ICreateUserPayload) => {
      const response = await userApi.createUser(payload)
      return response
    },
    onError: handleApiError,
    onSettled: () => {
      queryClient.invalidateQueries(userQueryKeys.user)
    },
  })
}

export const useUpdateUser = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (user: Pick<IUser, 'company' | 'firstName' | 'lastName'>) => {
      const response = await userApi.updateUser(user)
      return response
    },
    onError: handleApiError,
    onSettled: () => {
      queryClient.invalidateQueries(userQueryKeys.user)
    },
  })
}

export const useChangePassword = () => {
  return useMutation({
    mutationFn: async (data: IChangePasswordPayload) => {
      await userApi.changePassword(data)
    },
    onError: handleApiError,
  })
}
export const useAcceptInvitation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (invitationToken: string) => {
      const response = await client.patch(`/users/myself:join-tenant`, { invitationToken })
      return response.data
    },
    onError: handleApiError,
    onSettled: () => {
      queryClient.invalidateQueries(userQueryKeys.user)
    },
  })
}

export const useUserInformation = () => {
  return useQuery<IUser>({
    queryKey: userQueryKeys.user,
    queryFn: () => userApi.getUser(),
    enabled: Boolean(
      localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) || sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN),
    ),
    onError: (error) => {
      console.error('Failed to fetch user information:', error)
    },
  })
}
